import Web3 from 'web3/dist/web3.min.js';
const web3 = new Web3('https://polygon-mumbai.infura.io/v3/cd1922142cd94f3ca09289f67899f902');
const ABI = require(`./ethereumAndPolygonAbi.json`);

async function fetchNFTData(nftData) {
  const contractInstance = new web3.eth.Contract(ABI, nftData.contractAddress);
  if (nftData.title === 'xdai') {
    return await contractInstance.methods.uri(nftData.tokenId).call();
  } else {
    try {
      return await contractInstance.methods.tokenURI(nftData.tokenId).call();
    } catch (e) {
      return await contractInstance.methods.uri(nftData.tokenId).call();
    }
  }
}

async function checkIfWalletIsStillOwner(nftData) {
  const contractInstance = new web3.eth.Contract(ABI, nftData.contractAddress);
  try {
    return await contractInstance.methods.ownerOf(nftData.tokenID).call();
  } catch (e) {
    try {
      return await contractInstance.methods.owner().call();
    } catch (e) {
      return null;
    }
  }
}

export default { fetchNFTData, checkIfWalletIsStillOwner };
