<template>
  <div class="test-search-result-component-wrapper">
    <a :href="url" class="title">{{ title }}</a>
    <p class="description">{{ description }}</p>
    <div class="outward-links" v-if="!urls">
      <a class="link" v-if="techUrl" :href="techUrl">Technology Overview</a>
      <a class="link" v-if="getInTouchUrl" :href="getInTouchUrl">Get in Touch</a>
      <a class="link" v-if="communityUrl" :href="communityUrl">Our Community</a>
    </div>
    <div class="outward-links" v-else>
      <a class="link" :key="link.url + link.name" v-for="link of urls" :href="link.url">{{
        link.name
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextSearchResultComponent',
  props: {
    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    techUrl: {
      type: String,
      default: null,
    },
    getInTouchUrl: {
      type: String,
      default: null,
    },
    communityUrl: {
      type: String,
      default: null,
    },
    urls: {
      type: Array,
      default: () => null,
    },
  },
};
</script>

<style scoped lang="scss">
.test-search-result-component-wrapper {
  text-align: left;
  .title {
    color: var(--accent-blue);
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    text-decoration: none;
  }
  .description {
    margin-top: 1em;
    color: var(--black-secondary);
  }
  .outward-links {
    margin-top: 1em;
    display: flex;

    .link {
      color: var(--accent-blue);
      font-weight: 500;
      padding-right: 0.5em;
      text-decoration: none;
      &:not(:first-child) {
        padding-left: 0.5em;
      }
      &:not(:last-child) {
        border-right: 1px solid var(--grey-200);
      }
    }
  }
}
</style>
