<template>
  <div class="back-btn" @click="$router.back()">
    <img src="@/assets/icons/arrow-left.svg" alt="" />
    <div class="text">Back</div>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
};
</script>

<style lang="scss">
.back-btn {
  display: inline-flex;
  padding: 12px;
  border: 1px solid var(--grey-300);
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: var(--grey-300);

  .text {
    margin-left: 8px;
  }
}
</style>
