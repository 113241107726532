<template>
  <div class="tags" v-if="tags">
    <div :key="id + tag" v-for="tag of tags" :class="{ tag: true, [color]: !!color }">
      {{ tag }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagElement',
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'grey',
    },
  },
};
</script>

<style scoped lang="scss">
.tags {
  display: flex;
  .tag {
    background-color: #ededed;
    border-radius: 100px;
    padding: 6px;
    font-size: 12px;
    &:not(last-child) {
      margin-right: 0.5em;
    }
    &.blue {
      background-color: rgba(14, 63, 229, 0.1);
      color: #0e3fe5;
    }
  }
}
</style>
