<template>
  <div class="dropdown-wrapper">
    <b-dropdown text="Dropdown Button" class="button-dropdown">
      <template #button-content>
        <slot name="button"></slot>
      </template>
      <template v-for="(item, index) of menuItems">
        <b-dropdown-divider
          :key="index + 'menuDivider' + item.id"
          v-if="item.divider"
        ></b-dropdown-divider>
        <b-dropdown-item
          :disabled="item.disabled"
          @click="clicked(item.id)"
          :key="index + 'menuKey' + item.id"
          v-else
          >{{ item.title }}</b-dropdown-item
        >
      </template>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clicked(id) {
      this.$emit('itemClicked', id);
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-wrapper {
  ::v-deep .dropdown {
    button {
      border: 1px solid var(--grey-300);
      border-radius: 100px;
      background-color: transparent;
      color: var(--grey-400);
      font-size: 12px;
      padding: 8px 8px 8px 16px;

      &:after {
        margin-right: 8px;
      }
      &:focus {
      }
    }
    .dropdown-item {
      font-size: 14px;
      font-weight: 400;
      background-color: rgba(0, 0, 0, 0.04);
      padding: 12px;
      &:disabled,
      &.disabled {
        background-color: white;
      }
    }
    .dropdown-menu {
      padding: 0;
      border-radius: 16px;
      li {
        &:first-child {
          .dropdown-item {
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          }
        }
        &:last-child {
          .dropdown-item {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }
      }
    }
  }
}
</style>
