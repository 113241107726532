export default {
  getLanguages() {
    return {
      english: {
        'Training certificate': 'Training certificate',
        'Qualification certificate': 'Qualification certificate',
        Download: 'Download',
        Share: 'Share',
        'Certificate Verification': 'Certificate Verification',
        'BSI training certificate information': 'BSI training certificate information',
        'Certificate issuer': 'Certificate issuer',
        'Certificate holder': 'Certificate holder',
        'Certificate number': 'Certificate number',
        'Course name': 'Course name',
        'Course duration': 'Course duration',
        'Course date(s)': 'Course date(s)',
        'Data hash': 'Data hash',
        'Transaction hash': 'Transaction hash',
        'BSI qualification certificate information': 'BSI qualification certificate information',
        'Qualification name': 'Qualification name',
        'Qualification completion date': 'Qualification completion date',
        'Qualification expiry date': 'Qualification expiry date',
        'Share via email': 'Share via email',
        'Share via social networks': 'Share via social networks',
        'Invite people': 'Invite people',
        'E-mail': 'E-mail',
        'Message...': 'Message...',
        Message: 'Message',
        'Share public link': 'Share public link',
        'Public link': 'Public link',
        'Copy to clipboard': 'Copy to clipboard',
        'Public link is active': 'Public link is active',
        'Public link is disabled': 'Public link is disabled',
        'Shared with': 'Shared with',
        Revoke: 'Revoke',
        LinkedIn: 'LinkedIn',
        Twitter: 'Twitter',
        '[Popup] Share via email': '[Popup] Share via email',
        'Invite People': 'Invite People',
        Send: 'Send',
        '[Popup] Share via social networks': '[Popup] Share via social networks',
        Activity: 'Activity',
        'Visits from public link:': 'Visits from public link:',
        'visits in total': 'visits in total',
        'Visits from emailed links:': 'Visits from emailed links:',
        'Visits from social networks:': 'Visits from social networks:',
        'Restricted access': 'Restricted access',
        'This certificate verification link is no longer active.': 'This certificate verification link is no longer active.',
        'Verifying certificate issuer...': 'Verifying certificate issuer...',
        'Verifying BSI identity...': 'Verifying BSI identity...',
        'Verifying BSI signature...': 'Verifying BSI signature...',
        'Verifying certificate integrity...': 'Verifying certificate integrity...',
        'Verifying data model structure...': 'Verifying data model structure...',
        'Verifying Dataset replication on OriginTrail Decentralized Network...':
          'Verifying Dataset replication on OriginTrail Decentralized Network...',
        'CHECK ON ODN': 'CHECK ON ODN',
        'Computing data hash...': 'Computing data hash...',
        'Matching local and blockchain data hashes...': 'Matching local and blockchain data hashes...',
        'CHECK ON BLOCKCHAIN': 'CHECK ON BLOCKCHAIN',
        'Verifying certificate expiration status...': 'Verifying certificate expiration status...',
        'Verifying...': 'Verifying...',
        'VERIFY AGAIN': 'VERIFY AGAIN',
        'Certificate issuer verified': 'Certificate issuer verified',
        'BSI identity verified': 'BSI identity verified',
        'BSI signature verified': 'BSI signature verified',
        'Certificate integrity verified': 'Certificate integrity verified',
        'Data model structure verified': 'Data model structure verified',
        'Dataset replication on OriginTrail Decentralized Network verified':
          'Dataset replication on OriginTrail Decentralized Network verified',
        'Local data hash computed': 'Local data hash computed',
        'Match of local and blockchain data hashes established': 'Match of local and blockchain data hashes established',
        'Certificate expiration status OK': 'Certificate expiration status OK',
        'Certificate is valid!': 'Certificate is valid!',
        'Certificate data is published on OriginTrail Decentralized Network, and fingerprint of the data is anonymously stored on Ethereum.':
          'Certificate data is published on OriginTrail Decentralized Network, and fingerprint of the data is anonymously stored on Ethereum.',
        'Certificate issuer verification pending': 'Certificate issuer verification pending',
        'BSI identity verification pending': 'BSI identity verification pending',
        'BSI signature verification pending': 'BSI signature verification pending',
        'Certificate integrity verification pending': 'Certificate integrity verification pending',
        'Data model verification pending': 'Data model verification pending',
        'Dataset replication on OriginTrail Decentralized Network verification pending':
          'Dataset replication on OriginTrail Decentralized Network verification pending',
        'Computation of local data hashes pending': 'Computation of local data hashes pending',
        'Comparison of local and blockchain data hashes pending': 'Comparison of local and blockchain data hashes pending',
        'Certificate expiration status pending': 'Certificate expiration status pending',
        'Certificate verification pending': 'Certificate verification pending',
        'Certificate data was found but it cannot be verified on blockchain yet. This can take up to 24 hours. Please return later for more details.':
          'Certificate data was found but it cannot be verified on blockchain yet. This can take up to 24 hours. Please return later for more details.',
        'Certificate issuer could not be verified': 'Certificate issuer could not be verified',
        'BSI identity could not be verified': 'BSI identity could not be verified',
        'BSI signature could not be verified': 'BSI signature could not be verified',
        'Certificate integrity could not be verified': 'Certificate integrity could not be verified',
        'Data model could not be verified': 'Data model could not be verified',
        'Dataset replication on OriginTrail Decentralized Network could not be verified':
          'Dataset replication on OriginTrail Decentralized Network could not be verified',
        'Local data hash could not be computed': 'Local data hash could not be computed',
        'Match of local and blockchain data hashes could not be established':
          'Match of local and blockchain data hashes could not be established',
        'Certificate expiration status: Expired on ': 'Certificate expiration status: Expired on YYYY-MM-DD',
        'Could not verify certificate validity': 'Could not verify certificate validity',
        Information: 'Information',
        Verification: 'Verification',
        'Are your sure you want to revoke access for': 'Are your sure you want to revoke access for',
        'Revoke access': 'Revoke access',
        Yes: 'Yes',
        No: 'No',
        'Certificate not shared': 'Certificate not shared',
        'Sending email in progress, please wait…': 'Sending email in progress, please wait…',
        'Certificate shared successfully!': 'Certificate shared successfully!',
        'Certificate could not be shared, please try again.': 'Certificate could not be shared, please try again.',
        'Invalid email address.': 'Invalid email address.',
        'No visits': 'No visits',
        'Shared on': 'Shared on',
        'Access via LinkedIn revoked.': 'Access via LinkedIn revoked.',
        'Access via Twitter revoked.': 'Access via Twitter revoked.',
        'Certificate is already shared with this email address.': 'Certificate is already shared with this email address.',
        'Something went wrong, please try again!': 'Something went wrong, please try again!',
        'Copied to clipboard': 'Copied to clipboard',
        'Verifying assertion integrity...': 'Verifying assertion integrity...',
        'Assertion integrity verification pending': 'Assertion integrity verification pending',
        'Assertion integrity verified': 'Assertion integrity verified',
        'Assertion integrity could not be verified': 'Assertion integrity could not be verified',
        'Asset data is published on the OriginTrail Decentralized Knowledge Graph testnet.':
          'Asset data is published on the OriginTrail Decentralized Knowledge Graph testnet.',

        'Verifying issuer...': 'Verifying issuer...',
        'Issuer verification pending': 'Issuer verification pending',
        'Issuer verified': 'Issuer verified',
        'Issuer could not be verified': 'Issuer could not be verified',

        'Verifying data model...': 'Verifying data model...',
        'Verification of data model pending': 'Verification of data model pending',
        'Data model verified': 'Data model verified',
        'Assertion verification pending': 'Assertion verification pending',
        'Assertion is valid!': 'Assertion is valid!',
        'Could not verify assertion': 'Could not verify assertion',
        'Verifying assertion...': 'Verifying assertion...',

        'Verifying queried assertion fingerprint...': 'Verifying queried assertion fingerprint...',
        'Queried assertion fingerprint verification pending': 'Queried assertion fingerprint verification pending',
        'The queried assertion fingerprint matches the one stored on the blockchain':
          'The queried assertion fingerprint matches the one stored on the blockchain',
        'Queried assertion fingerprint could not be verified': 'Queried assertion fingerprint could not be verified',
        'Verifying assertion issuer signature...': 'Verifying assertion issuer signature...',
        'Assertion issuer signature verification pending': 'Assertion issuer signature verification pending',
        'The assertion issuer signature is valid': 'The assertion issuer signature is valid',
        'Assertion issuer signature could not be verified': 'Assertion issuer signature could not be verified',
        'Verifying the assertion data model...': 'Verifying the assertion data model...',
        'Verification of the assertion data model pending': 'Verification of the assertion data model pending',
        'The assertion data model is as expected': 'The assertion data model is as expected',
        'The assertion data model could not be verified': 'The assertion data model could not be verified',
      },
      chinese: {
        'Training certificate': '培訓證書',
        'Qualification certificate': '資質證書',
        Download: '下載',
        Share: '分享',
        'Certificate Verification': '證書驗證',
        'BSI training certificate information': 'BSI培訓證書信息',
        'Certificate issuer': '證書發行者',
        'Certificate holder': '證書持有人',
        'Certificate number': '證書編號',
        'Course name': '課程名',
        'Course duration': '課程時間',
        'Course date(s)': '課程日期',
        'Data hash': '資料雜湊',
        'Transaction hash': '交易哈希',
        'BSI qualification certificate information': 'BSI資格證書信息',
        'Qualification name': '資格名稱',
        'Qualification completion date': '資格完成日期',
        'Qualification expiry date': '資格到期日',
        'Share via email': '通過電子郵件分享',
        'Share via social networks': '通過社交網絡分享',
        'Invite people': '邀請人',
        'E-mail': '電子郵件',
        'Message...': '信息...',
        Message: '信息',
        'Share public link': '分享公共鏈接',
        'Public link': '公開連結',
        'Copy to clipboard': '複製到剪貼板',
        'Public link is active': '公共鏈接處於活動狀態',
        'Public link is disabled': '公共鏈接已禁用',
        'Shared with': '跟...一起分享',
        Revoke: '撤消',
        LinkedIn: '領英',
        Twitter: '推特',
        '[Popup] Share via email': '[彈出]通過電子郵件分享',
        'Invite People': '邀請人',
        Send: '發送',
        '[Popup] Share via social networks': '[彈出]通過社交網絡分享',
        Activity: '活動',
        'Visits from public link:': '來自公共鏈接的訪問',
        'visits in total': '總共x次造訪',
        'Visits from emailed links:': '通過電子郵件鏈接訪問',
        'Visits from social networks:': '來自社交網絡的訪問',
        'Restricted access': '禁止進入',
        'This certificate verification link is no longer active.': '該證書驗證鏈接不再有效',
        'Verifying certificate issuer...': '正在驗證證書頒發者...',
        'Verifying BSI identity...': '驗證BSI身份...',
        'Verifying BSI signature...': '驗證BSI簽名...',
        'Verifying certificate integrity...': '驗證證書完整性...',
        'Verifying data model structure...': '驗證數據模型結構...',
        'Verifying Dataset replication on OriginTrail Decentralized Network...': '正在驗證OriginTrail分散網絡上的數據集複製...',
        'CHECK ON ODN': '檢查ODN',
        'Computing data hash...': '正在計算數據哈希...',
        'Matching local and blockchain data hashes...': '匹配本地和區塊鏈數據哈希...',
        'CHECK ON BLOCKCHAIN': '檢查BLOCKCHAIN',
        'Verifying certificate expiration status...': '驗證證書到期狀態...',
        'Verifying...': '驗證中...',
        'VERIFY AGAIN': '再次驗證',
        'Certificate issuer verified': '證書發行人已驗證',
        'BSI identity verified': 'BSI身份驗證',
        'BSI signature verified': 'BSI簽名已驗證',
        'Certificate integrity verified': '證書完整性已驗證',
        'Data model structure verified': '數據模型結構已驗證',
        'Dataset replication on OriginTrail Decentralized Network verified': '已驗證OriginTrail分散網絡上的數據集複製',
        'Local data hash computed': '計算本地數據哈希',
        'Match of local and blockchain data hashes established': '建立本地和區塊鏈數據哈希的匹配',
        'Certificate expiration status OK': '證書到期狀態正常',
        'Certificate is valid!': '證書有效！',
        'Certificate data is published on OriginTrail Decentralized Network, and fingerprint of the data is anonymously stored on Ethereum.':
          '證書數據在OriginTrail去中心化網絡上發布，數據的指紋匿名存儲在以太坊區塊鏈上。',
        'Certificate issuer verification pending': '證書頒發者驗證待定',
        'BSI identity verification pending': 'BSI身份驗證待定',
        'BSI signature verification pending': 'BSI簽名驗證待定',
        'Certificate integrity verification pending': '證書完整性驗證待定',
        'Data model verification pending': '數據模型驗證待定',
        'Dataset replication on OriginTrail Decentralized Network verification pending': 'OriginTrail分散網絡驗證中的數據集複製正在等待中',
        'Computation of local data hashes pending': '計算本地數據哈希',
        'Comparison of local and blockchain data hashes pending': '比較本地和區塊鏈數據哈希',
        'Certificate expiration status pending': '證書到期狀態待定',
        'Certificate verification pending': '證書驗證待定',
        'Certificate data was found but it cannot be verified on blockchain yet. This can take up to 24 hours. Please return later for more details.':
          '已找到證書數據，但尚未在區塊鏈上對其進行驗證。這最多可能需要24個小時。請稍後返回以獲取更多詳細信息。',
        'Certificate issuer could not be verified': '證書發行者無法驗證',
        'BSI identity could not be verified': '無法驗證BSI身份',
        'BSI signature could not be verified': '無法驗證BSI簽名',
        'Certificate integrity could not be verified': '證書完整性無法驗證',
        'Data model could not be verified': '數據模型無法驗證',
        'Dataset replication on OriginTrail Decentralized Network could not be verified': '無法驗證OriginTrail分散網絡上的數據集複製',
        'Local data hash could not be computed': '無法計算本地數據哈希',
        'Match of local and blockchain data hashes could not be established': '無法建立本地和區塊鏈數據哈希的匹配',
        'Certificate expiration status: Expired on ': '證書過期狀態：YYYY-MM-DD過期',
        'Could not verify certificate validity': '無法驗證證書的有效性',
        Information: '信息',
        Verification: '驗證',
        'Are your sure you want to revoke access for': '您確定要撤消對[電子郵件地址]的訪問權限嗎？',
        'Revoke access': '撤消訪問權限',
        Yes: '是',
        No: '沒有',
        'Certificate not shared': '證書未共享',
        'Sending email in progress, please wait…': '正在發送電子郵件，請稍候...',
        'Certificate shared successfully!': '證書共享成功！',
        'Certificate could not be shared, please try again.': '無法共享證書，請重試。',
        'Invalid email address.': '無效的郵件地址。',
        'No visits': '沒有訪問',
        'Shared on': '分享到',
        'Access via LinkedIn revoked.': '通過LinkedIn的訪問已被撤消。',
        'Access via Twitter revoked.': '通過Twitter的訪問已被撤消。',
        'Certificate is already shared with this email address.': '證書已經與此電子郵件地址共享',
        'Something went wrong, please try again!': '出問題了，請重試！',
        'Copied to clipboard': '複製到剪貼板',
      },
      french: {
        'Training certificate': 'Attestation de formation',
        'Qualification certificate': 'Certificat de qualification',
        Download: 'Télécharger',
        Share: 'Partager',
        'Certificate Verification': 'Vérification du certificat',
        'BSI training certificate information': 'Informations sur le certificat de formation BSI',
        'Certificate issuer': 'Émetteur de certificat',
        'Certificate holder': 'Titulaire du certificat',
        'Certificate number': 'Numéro de certificat',
        'Course name': 'Nom du cours',
        'Course duration': 'Durée du cours',
        'Course date(s)': 'Date (s) du cours',
        'Data hash': 'Hachage de données',
        'Transaction hash': 'Transaction de hachage',
        'BSI qualification certificate information': 'Informations sur le certificat de qualification BSI',
        'Qualification name': 'Nom de la qualification',
        'Qualification completion date': "Date d'achèvement de la qualification",
        'Qualification expiry date': "Date d'expiration de la qualification",
        'Share via email': 'Partager',
        'Share via social networks': 'Partager via les réseaux sociaux',
        'Invite people': 'Inviter des collaborateurs',
        'E-mail': 'Courrier électronique',
        'Message...': 'Message...',
        Message: 'Message',
        'Share public link': 'Partager un lien public',
        'Public link': 'Lien public',
        'Copy to clipboard': 'Copier dans le presse-papier',
        'Public link is active': 'Le lien public est actif',
        'Public link is disabled': 'Le lien public est désactivé',
        'Shared with': 'Partagé avec',
        Revoke: 'Révoquer',
        LinkedIn: 'LinkedIn',
        Twitter: 'Twitter',
        '[Popup] Share via email': '[Popup] Partager par e-mail',
        'Invite People': 'Inviter des collaborateurs',
        Send: 'Envoyer',
        '[Popup] Share via social networks': '[Popup] Partager via les réseaux sociaux',
        Activity: 'Activité',
        'Visits from public link:': 'Visites à partir du lien public:',
        'visits in total': 'visites au total',
        'Visits from emailed links:': 'Visites à partir de liens envoyés par email:',
        'Visits from social networks:': 'Visites des réseaux sociaux:',
        'Restricted access': 'Accès restreint',
        'This certificate verification link is no longer active.': "Ce lien de vérification de certificat n'est plus actif.",
        'Verifying certificate issuer...': "Vérification de l'émetteur du certificat ...",
        'Verifying BSI identity...': "Vérification de l'identité BSI ...",
        'Verifying BSI signature...': 'Vérification de la signature BSI ...',
        'Verifying certificate integrity...': "Vérification de l'intégrité du certificat ...",
        'Verifying data model structure...': 'Vérification de la structure du modèle de données ...',
        'Verifying Dataset replication on OriginTrail Decentralized Network...':
          'Vérification de la réplication des données sur le réseau décentralisé OriginTrail ...',
        'CHECK ON ODN': 'VÉRIFIEZ SUR ODN',
        'Computing data hash...': 'Calcul du hachage des données ...',
        'Matching local and blockchain data hashes...': 'Correspondance des hachages de données locaux et blockchain ...',
        'CHECK ON BLOCKCHAIN': 'VÉRIFIER SUR BLOCKCHAIN',
        'Verifying certificate expiration status...': "Vérification du statut d'expiration du certificat ...",
        'Verifying...': 'Vérification ...',
        'VERIFY AGAIN': 'VERIFIER DE NOUVEAU',
        'Certificate issuer verified': 'Émetteur de certificat vérifié',
        'BSI identity verified': 'Identité BSI vérifiée',
        'BSI signature verified': 'Signature BSI vérifiée',
        'Certificate integrity verified': "Vérification terminée de l'intégrité du certificat",
        'Data model structure verified': 'Structure du modèle de données vérifiée',
        'Dataset replication on OriginTrail Decentralized Network verified':
          'Vérification terminée de la réplication des données sur le réseau décentralisé OriginTrail',
        'Local data hash computed': 'Hachage des données locales terminé',
        'Match of local and blockchain data hashes established': 'Correspondance des hachages de données locaux et blockchain vérifiée ...',
        'Certificate expiration status OK': "Statut d'expiration du certificat : OK",
        'Certificate is valid!': 'Le certificat est valide!',
        'Certificate data is published on OriginTrail Decentralized Network, and fingerprint of the data is anonymously stored on Ethereum.':
          "Les données du certificat sont publiées sur le réseau décentralisé OriginTrail et l'empreinte digitale des données est stockée de manière anonyme par blockchain Ethereum.",
        'Certificate issuer verification pending': "Vérification de l'émetteur du certificat en attente",
        'BSI identity verification pending': "Vérification d'identité BSI en attente",
        'BSI signature verification pending': 'Vérification de la signature BSI en attente',
        'Certificate integrity verification pending': "Vérification de l'intégrité du certificat en attente",
        'Data model verification pending': 'Vérification du modèle de données en attente',
        'Dataset replication on OriginTrail Decentralized Network verification pending':
          'Vérification terminée de la réplication des données sur le réseau décentralisé OriginTrail en attente',
        'Computation of local data hashes pending': 'Hachage des données locales en attente',
        'Comparison of local and blockchain data hashes pending': 'Correspondance des hachages de données locaux et blockchain en attente',
        'Certificate expiration status pending': "Statut d'expiration du certificat : en attente",
        'Certificate verification pending': 'Vérification du certificat en attente',
        'Certificate data was found but it cannot be verified on blockchain yet. This can take up to 24 hours. Please return later for more details.':
          "Les données du certificat ont été identifiées, mais elles ne peuvent pas encore être vérifiées par blockchain. Cela peut prendre jusqu'à 24 heures. Veuillez réessayer plus tard pour plus de résultats.",
        'Certificate issuer could not be verified': "L'émetteur du certificat n'a pas pu être vérifié",
        'BSI identity could not be verified': "L'identité BSI n'a pas pu être vérifiée",
        'BSI signature could not be verified': "La signature BSI n'a pas pu être vérifiée",
        'Certificate integrity could not be verified': "L'intégrité du certificat n'a pas pu être vérifiée",
        'Data model could not be verified': "Le modèle de données n'a pas pu être vérifié",
        'Dataset replication on OriginTrail Decentralized Network could not be verified':
          "La réplication de l'ensemble de données sur le réseau décentralisé OriginTrail n'a pas pu être vérifiée",
        'Local data hash could not be computed': "Le hachage des données locales n'a pas pu être calculé",
        'Match of local and blockchain data hashes could not be established':
          "La correspondance des hachages de données locaux et blockchain n'a pas pu être établie",
        'Certificate expiration status: Expired on ': "Statut d'expiration du certificat: expiré le AAAA-MM-JJ",
        'Could not verify certificate validity': 'Impossible de vérifier la validité du certificat',
        Information: 'Information',
        Verification: 'Vérification',
        'Are your sure you want to revoke access for': "Voulez-vous vraiment révoquer l'accès pour",
        'Revoke access': 'Accès révoqué',
        Yes: 'Oui',
        No: 'Non',
        'Certificate not shared': 'Certificat non partagé',
        'Sending email in progress, please wait…': "Envoi d'un e-mail en cours, veuillez patienter…",
        'Certificate shared successfully!': 'Certificat partagé avec succès!',
        'Certificate could not be shared, please try again.': "Le certificat n'a pas pu être partagé, veuillez réessayer.",
        'Invalid email address.': 'Adresse e-mail invalide.',
        'No visits': 'Aucune visite',
        'Shared on': 'Partagé sur',
        'Access via LinkedIn revoked.': "L'accès via LinkedIn a été révoqué.",
        'Access via Twitter revoked.': 'Accès via Twitter révoqué.',
        'Certificate is already shared with this email address.': 'Le certificat est déjà partagé avec cette adresse e-mail.',
        'Something went wrong, please try again!': "Une erreur s'est produite. Veuillez réessayer!",
        'Copied to clipboard': 'Copié dans le presse-papier',
      },
      japanese: {
        'Training certificate': 'トレーニング証明書',
        'Qualification certificate': '資格証明書',
        Download: 'ダウンロード',
        Share: 'シェア',
        'Certificate Verification': '証明書の検証',
        'BSI training certificate information': 'BSIトレーニング証明書情報',
        'Certificate issuer': '証明書発行者',
        'Certificate holder': '証明書保有者',
        'Certificate number': '証明書番号',
        'Course name': 'コース名',
        'Course duration': 'コース期間',
        'Course date(s)': 'コースの日付',
        'Data hash': 'データハッシュ',
        'Transaction hash': 'トランザクションハッシュ',
        'BSI qualification certificate information': 'BSI資格証明書情報',
        'Qualification name': '資格名',
        'Qualification completion date': '資格取得日',
        'Qualification expiry date': '資格の有効期限',
        'Share via email': 'メールで共有',
        'Share via social networks': 'ソーシャルネットワークを介して共有する',
        'Invite people': '人を招待する',
        'E-mail': 'Eメール',
        'Message...': 'メッセージ...',
        Message: 'メッセージ',
        'Share public link': 'パブリックリンクを共有する',
        'Public link': 'パブリックリンク',
        'Copy to clipboard': 'クリップボードにコピー',
        'Public link is active': 'パブリックリンクがアクティブです',
        'Public link is disabled': 'パブリックリンクが無効になっています',
        'Shared with': 'と共有',
        Revoke: '取り消す',
        LinkedIn: 'LinkedIn',
        Twitter: 'ツイッター',
        '[Popup] Share via email': '[Popup]メールで共有',
        'Invite People': '人を招待する',
        Send: '送信',
        '[Popup] Share via social networks': '[Popup]ソーシャルネットワーク経由で共有',
        Activity: 'アクティビティ',
        'Visits from public link:': 'パブリックリンクからの訪問：',
        'visits in total': '合計x回の訪問',
        'Visits from emailed links:': '電子メールによるリンクからの訪問：',
        'Visits from social networks:': 'ソーシャルネットワークからの訪問：',
        'Restricted access': '制限付きアクセス',
        'This certificate verification link is no longer active.': 'この証明書検証リンクはアクティブではなくなりました。',
        'Verifying certificate issuer...': '証明書発行者の確認...',
        'Verifying BSI identity...': 'BSIIDの確認...',
        'Verifying BSI signature...': 'BSI署名の検証...',
        'Verifying certificate integrity...': '証明書の整合性を検証しています...',
        'Verifying data model structure...': 'データモデル構造の検証...',
        'Verifying Dataset replication on OriginTrail Decentralized Network...':
          'OriginTrail分散型ネットワークでのデータセットレプリケーションの検証...',
        'CHECK ON ODN': 'ODNを確認してください',
        'Computing data hash...': 'データハッシュの計算...',
        'Matching local and blockchain data hashes...': 'ローカルデータハッシュとブロックチェーンデータハッシュのマッチング..',
        'CHECK ON BLOCKCHAIN': 'BLOCKCHAINを確認してください',
        'Verifying certificate expiration status...': '証明書の有効期限ステータスを確認しています...',
        'Verifying...': '確認中...',
        'VERIFY AGAIN': 'もう一度確認してください',
        'Certificate issuer verified': '証明書の発行者が確認済み',
        'BSI identity verified': 'BSIIDが検証されました',
        'BSI signature verified': 'BSI署名が検証されました',
        'Certificate integrity verified': '証明書の整合性が検証されました',
        'Data model structure verified': '検証されたデータモデル構造',
        'Dataset replication on OriginTrail Decentralized Network verified':
          'OriginTrail分散型ネットワークでのデータセットレプリケーションが検証されました',
        'Local data hash computed': '計算されたローカルデータハッシュ',
        'Match of local and blockchain data hashes established':
          'ローカルデータハッシュとブロックチェーンデータハッシュの一致が確立されました',
        'Certificate expiration status OK': '証明書の有効期限ステータスOK',
        'Certificate is valid!': '証明書は有効です！',
        'Certificate data is published on OriginTrail Decentralized Network, and fingerprint of the data is anonymously stored on Ethereum.':
          '証明書データはOriginTrailDecentralized Networkで公開され、データのフィンガープリントは匿名でイーサリアムブロックチェーンに保存されます。',
        'Certificate issuer verification pending': '証明書発行者の検証は保留中',
        'BSI identity verification pending': 'BSIID検証は保留中',
        'BSI signature verification pending': 'BSI署名の検証は保留中',
        'Certificate integrity verification pending': '証明書の整合性検証は保留中',
        'Data model verification pending': 'データモデルの検証は保留中',
        'Dataset replication on OriginTrail Decentralized Network verification pending':
          'OriginTrail分散型ネットワークでのデータセットレプリケーションは保留中です',
        'Computation of local data hashes pending': 'ローカルデータハッシュの計算は保留中',
        'Comparison of local and blockchain data hashes pending': '保留中のローカルデータハッシュとブロックチェーンデータハッシュの比較',
        'Certificate expiration status pending': '証明書の有効期限ステータスは保留中',
        'Certificate verification pending': '証明書の検証は保留中',
        'Certificate data was found but it cannot be verified on blockchain yet. This can take up to 24 hours. Please return later for more details.':
          '証明書データが見つかりましたが、ブロックチェーンでまだ確認できません。 これには最大24時間かかる場合があります。 詳細については、後で戻ってください。',
        'Certificate issuer could not be verified': '証明書の発行者を確認できませんでした',
        'BSI identity could not be verified': 'BSIIDを確認できませんでした',
        'BSI signature could not be verified': 'BSI署名を検証できませんでした',
        'Certificate integrity could not be verified': '証明書の整合性を検証できませんでした',
        'Data model could not be verified': 'データモデルを検証できませんでした',
        'Dataset replication on OriginTrail Decentralized Network could not be verified':
          'OriginTrail分散型ネットワークでのデータセットレプリケーションを検証できませんでした',
        'Local data hash could not be computed': 'ローカルデータハッシュを計算できませんでした',
        'Match of local and blockchain data hashes could not be established':
          'ローカルデータハッシュとブロックチェーンデータハッシュの一致を確立できませんでした',
        'Certificate expiration status: Expired on ': '証明書の有効期限ステータス：YYYY-MM-DDで期限切れ',
        'Could not verify certificate validity': '証明書の有効性を確認できませんでした',
        Information: '情報',
        Verification: '検証',
        'Are your sure you want to revoke access for': 'へのアクセスを取り消してもよろしいですか',
        'Revoke access': 'アクセス権を取り消す',
        Yes: 'はい',
        No: '番号',
        'Certificate not shared': '証明書が共有されていません',
        'Sending email in progress, please wait…': '進行中のメールを送信しています。しばらくお待ちください…',
        'Certificate shared successfully!': '証明書が正常に共有されました！',
        'Certificate could not be shared, please try again.': '証明書を共有できませんでした。もう一度やり直してください。',
        'Invalid email address.': '無効なメールアドレス。',
        'No visits': '訪問なし',
        'Shared on': '共有',
        'Access via LinkedIn revoked.': 'LinkedIn経由のアクセスが取り消されました。',
        'Access via Twitter revoked.': 'Twitter経由のアクセスが取り消されました。',
        'Certificate is already shared with this email address.': '証明書はすでにこのメールアドレスと共有されています。',
        'Something went wrong, please try again!': '何かが間違っていました。もう一度やり直してください！',
        'Copied to clipboard': 'クリップボードにコピー',
      },
      turkish: {
        'Training certificate': 'Eğitim sertifikası',
        'Qualification certificate': 'Kalifikasyon sertifikası',
        Download: 'İndir',
        Share: 'Paylaş',
        'Certificate Verification': 'Sertifika doğrulama',
        'BSI training certificate information': 'BSI eğitim sertifikası bilgileri',
        'Certificate issuer': 'Sertifikayı hazırlayan',
        'Certificate holder': 'Sertifikayı alan',
        'Certificate number': 'Sertifika numarası',
        'Course name': 'Eğitim adı',
        'Course duration': 'Eğitim süresi',
        'Course date(s)': 'Eğitim tarih(ler)i',
        'Data hash': 'Veri özetleme (Data hash) ',
        'Transaction hash': 'İşlem karması (Transaction hash) ',
        'BSI qualification certificate information': 'BSI kalifikasyon sertifikası bilgileri',
        'Qualification name': 'Kalifikasyon adı',
        'Qualification completion date': 'Kalifikasyon tamamlama tarihi',
        'Qualification expiry date': 'Kalifikasyon geçerlilik tarihi',
        'Share via email': 'E-posta ile paylaş',
        'Share via social networks': 'Sosyal ağlar aracılığı ile paylaş',
        'Invite people': 'Kişileri davet et',
        'E-mail': 'E-posta',
        'Message...': 'Mesaj…',
        Message: 'Mesaj',
        'Share public link': 'Genel kullanıma açık bağlantı paylaş',
        'Public link': 'Genel kullnaıma açık bağlantı',
        'Copy to clipboard': 'Panoya kopyala',
        'Public link is active': 'Genel kullanıma açık bağlantı aktif',
        'Public link is disabled': 'Genel kullanıma açık bağlantı aktif değil',
        'Shared with': 'Şununla paylaş',
        Revoke: 'İptal et',
        LinkedIn: 'LinkedIn',
        Twitter: 'Twitter',
        '[Popup] Share via email': 'E-posta ile paylaş',
        'Invite People': 'Kişileri davet et',
        Send: 'Send',
        '[Popup] Share via social networks': 'Sosyal ağlar aracılığı ile paylaş',
        Activity: 'Aktivite',
        'Visits from public link:': 'Genel kullanıma açık bağlantıdan yapılan ziyaretler:',
        'visits in total': 'Toplamda ziyaret',
        'Visits from emailed links:': 'E-posta bağlantılarından yapılan ziyaretler:',
        'Visits from social networks:': 'Sosyal ağlardan yapılan ziyaretler:',
        'Restricted access': 'Erişim sınırlandırması',
        'This certificate verification link is no longer active.': 'Bu sertifika için doğrulama aktif değil',
        'Verifying certificate issuer...': 'Sertifika hazırlayıcı doğrulanıyor…',
        'Verifying BSI identity...': 'BSI kimliği doğrulanıyor…',
        'Verifying BSI signature...': 'BSI imzası doğrulanıyor…',
        'Verifying certificate integrity...': 'Sertifika içeriği doğrulanıyor…',
        'Verifying data model structure...': 'Veri modeli yapısı doğrulanıyor…',
        'Verifying Dataset replication on OriginTrail Decentralized Network...':
          'OriginTrail Decentralized Network (ODN) üzerinden veri kopyalaması doğrulanıyor…',
        'CHECK ON ODN': 'OriginTrail Decentralized Network (ODN) üzerinden kontrol edin',
        'Computing data hash...': '(Data hash) Özet veri hesaplanıyor',
        'Matching local and blockchain data hashes...': 'Yerel ve Blockchain özet verileri eşleştiriliyor...',
        'CHECK ON BLOCKCHAIN': 'BLOCKCHAIN KONTROLÜ YAPILIYOR…',
        'Verifying certificate expiration status...': 'Sertifika geçerlilik tarihi doğrulanıyor…',
        'Verifying...': 'Doğrulanıyor…',
        'VERIFY AGAIN': 'TEKRAR DOĞRULA',
        'Certificate issuer verified': 'Sertifka hazırlayıcı doğrulandı',
        'BSI identity verified': 'BSI kimliği doğrulandı',
        'BSI signature verified': 'BSI imzası doğrulandı',
        'Certificate integrity verified': 'Sertifka içeriği doğrulandı',
        'Data model structure verified': 'Veri modeli yapısı doğrulandı',
        'Dataset replication on OriginTrail Decentralized Network verified':
          'OriginTrail Decentralized Network (ODN) üzerinden veri kopyalama doğrulandı',
        'Local data hash computed': 'Yerel (data hash) özet veri hesaplandı',
        'Match of local and blockchain data hashes established': 'Yerel ve Blockchain özet verileri eşleştirildi',
        'Certificate expiration status OK': 'Sertifika geçerlilik tarihi doğrulandı!',
        'Certificate is valid!': 'BSertifika doğrulandı!',
        'Certificate data is published on OriginTrail Decentralized Network, and fingerprint of the data is anonymously stored on Ethereum.':
          "Sertifika verileri ODN (OriginTrail Decentralized Network)'de yayınlandı ve verilerin parmak izi anonim olarak Blockchain'e kaydedildi.",
        'Certificate issuer verification pending': 'Sertifka hazırlayıcı doğrulaması bekleniyor',
        'BSI identity verification pending': 'BSI kimliği doğrulaması bekleniyor',
        'BSI signature verification pending': 'BSI imzası doğrulaması bekleniyor',
        'Certificate integrity verification pending': 'Sertifka içeriği doğrulaması bekleniyor',
        'Data model verification pending': 'Veri modeli yapısı doğrulaması bekleniyor',
        'Dataset replication on OriginTrail Decentralized Network verification pending':
          'OriginTrail Decentralized Network (ODN) üzerinden veri kopyalama bekleniyor',
        'Computation of local data hashes pending': 'Yerel (data hash) özet veri hesaplaması bekleniyor',
        'Comparison of local and blockchain data hashes pending': 'Yerel ve Blockchain özet verilerin eşleştirilmesi bekleniyor',
        'Certificate expiration status pending': 'Sertifika geçerlilik tarihi doğrulaması bekleniyor',
        'Certificate verification pending': 'Sertifika doğrulaması bekleniyor',
        'Certificate data was found but it cannot be verified on blockchain yet. This can take up to 24 hours. Please return later for more details.':
          'Sertifika verileri bulundu ancak henüz doğrulama yapılamıyor. Güncelleme süresi 24 saat kadar sürebilir. Daha fazla ayrıntı için lütfen daha sonra tekrar giriş yapın.',
        'Certificate issuer could not be verified': 'Sertifka hazırlayıcı doğrulanamadı',
        'BSI identity could not be verified': 'BSI kimliği doğrulanamadı',
        'BSI signature could not be verified': 'BSI imzası doğrulanamadı',
        'Certificate integrity could not be verified': 'Sertifka içeriği doğrulanamadı',
        'Data model could not be verified': 'Veri modeli yapısı doğrulanamadı',
        'Dataset replication on OriginTrail Decentralized Network could not be verified':
          'OriginTrail Decentralized Network (ODN) üzerinden veri kopyalama doğrulanamadı',
        'Local data hash could not be computed': 'Yerel (data hash) özet veri hesaplanamadı',
        'Match of local and blockchain data hashes could not be established': 'Yerel ve Blockchain özet verileri eşleştirilemedi',
        'Certificate expiration status: Expired on ': 'Sertifika geçerlilik durumu: tarihinde geçerlilik süresi doldu',
        'Could not verify certificate validity': 'Sertifika doğrulanamadı',
        Information: 'Bilgi',
        Verification: 'Doğrula',
        'Are your sure you want to revoke access for': 'için erişimi iptal etmek istediğinize emin misiniz?',
        'Revoke access': 'Erişimi iptal et',
        Yes: 'Evet',
        No: 'Hayır',
        'Certificate not shared': 'Sertifika paylaşılmadı',
        'Sending email in progress, please wait…': 'E-posta gönderiliyor, lütfen bekleyin…',
        'Certificate shared successfully!': 'Sertifika paylaşımı başarılı!',
        'Certificate could not be shared, please try again.': 'Sertifika paylaşılamıyor, lütfen tekrar deneyin.',
        'Invalid email address.': 'Geçersiz e-posta adresi.',
        'No visits': 'Hiç ziyaret yok',
        'Shared on': 'Şurada paylaş',
        'Access via LinkedIn revoked.': 'LinkedIn yoluyla erişim bağlantısı iptal edildi.',
        'Access via Twitter revoked.': 'Twitter yoluyla erişim bağlantısı iptal edildi.',
        'Certificate is already shared with this email address.': 'Sertifika  bu e-posta adresi ile paylaşmak için hazır.',
        'Something went wrong, please try again!': 'Bir hata oluştu, lütfen tekrar deneyin!',
        'Copied to clipboard': 'Panoya kopyalandı',
      },
    };
  },
};
