<template>
  <div :class="{ 'header-wrapper row': true, 'with-border': showSearchField }">
    <div class="logo col-7 col-sm-6 order-0 order col-md-3 mt-2 mt-lg-0">
      <a href="/" v-if="showLogo"><img class="logo-img" src="@/assets/images/logo.svg" /></a>
    </div>
    <div class="search-bar order-2 order-md-1 col-12 col-md-5 col-xl-6 mt-4 mt-md-0 d-flex">
      <search-bar
        :is-searching="searching"
        :input-key="searchKeys"
        @onSearch="goToSearchPage"
        v-if="showSearchField"
      >
      </search-bar>
    </div>
    <div
      :class="{
        'user-panel col-5 col-sm-6 order-1 order-md-2 col-md-4 col-xl-3 mt-2 mt-lg-0 d-flex justify-content-end': true,
        'col-12 full': !showSearchField && !showLogo,
      }"
    >
      <div class="d-flex me-md-0 ms-md-auto flex-wrap">
        <dropdown
          id="dropdown-network"
          @itemClicked="onNetworkClicked"
          :menu-items="networks"
          class="me-2"
        >
          <template slot="button">
            <div class="network-name">{{ currentNetworkName }}</div>
            <div class="network-name short">{{ currentNetworkName[0] }}</div>
          </template>
        </dropdown>
        <dropdown
          v-if="walletConnected"
          :menu-items="userMenuItems"
          @itemClicked="onUserDropdownItemClicked"
        >
          <template slot="button">
            <span class="avatar-hash"><img src="@/assets/icons/hash_avatar.svg" /></span>
            <span class="address-text">{{ getAddressShortForm(address) }}</span>
          </template>
        </dropdown>
        <div class="d-flex" v-else>
          <connect-wallet-button @click="connectToMetamask" class="my-auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from './SearchBar';
import Dropdown from './Dropdown';
import ConnectWalletButton from './ConnectWalletButton';
import { networkList } from '../utils/lists';

export default {
  name: 'Header',
  components: { ConnectWalletButton, Dropdown, SearchBar },
  data() {
    return {
      searchKeys: this.getKeyword(),
      walletConnected: false,
      networks: networkList,
      userMenuItems: [
        {
          title: 'Logout',
          id: 1,
        },
      ],
      address: '',
    };
  },
  computed: {
    searching() {
      return this.$store.getters.isSearching;
    },
    showLogo() {
      return this.$route.name !== 'Home';
    },
    showSearchField() {
      return this.$route.name !== 'Home';
    },
    searchCalled() {
      return this.$store.getters.getSearchKeyword;
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
    connectedWallet() {
      return this.$store.getters.connectedAddress;
    },
    selectedNetwork() {
      return this.$store.getters.selectedNetwork;
    },
    currentNetworkName() {
      return this.selectedNetwork?.title ?? '';
    },
  },
  watch: {
    searchCalled(newValue) {
      this.searchKeys = newValue;
    },
    isConnected(newValue) {
      this.walletConnected = newValue;
    },
    connectedWallet(newValue) {
      this.address = newValue;
    },
  },
  created() {
    this.searchKeys = this.getKeyword();
  },
  methods: {
    updateSearchValue(value) {
      this.searchKeys = value;
    },
    goToSearchPage(key) {
      if (this.searching) {
        return;
      }
      if (this.$route.name !== 'Search') {
        this.$router.push(`/search-result?key=${key}`);
      } else {
        this.$router.replace({ query: { key } });
      }
      this.$store.dispatch('callSearch', key);
    },
    getKeyword() {
      return this.$route.query.key;
    },
    getAddressShortForm(address) {
      let value = address?.match(/^([\s\S]{4})([\s\S]*?)([\s\S]{4})$/i);
      return value[1] + '...' + value[3];
    },
    onNetworkClicked(networkId) {
      console.log(networkId);
    },
    onUserDropdownItemClicked(itemId) {
      switch (itemId) {
        case 1: {
          return this.logout();
        }
      }
    },
    connectToMetamask() {
      this.$store.dispatch('connectWithMetamask');
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped lang="scss">
#dropdown-network.dropdown-menu {
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    top: 10px !important;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
}

.header-wrapper {
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    top: 10px !important;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  //height: 107px;
  padding: 20px 0px 25px;

  .logo {
    text-align: left;
    .logo-img {
      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 180px;
        margin-bottom: 10px;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 180px;
        margin-bottom: 0px;
      }
    }
  }

  .search-bar {
    .search-bar-wrapper {
      width: 100%;
    }
  }

  .avatar-hash {
    img {
      margin-right: 8px;
    }
  }

  .user-panel {
    .dropdown-wrapper {
      .dropdown-menu {
        margin-top: 10px;
      }

      height: auto;
      vertical-align: center;
      display: flex;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
      ::v-deep .dropdown {
        margin-top: auto;
        margin-bottom: auto;
      }
      .network-name {
        max-width: 2vw;
        text-overflow: ellipsis;
        display: none;
        @media screen and (min-width: 576px) {
          display: inline;
        }
        &.short {
          display: inline;
          @media screen and (min-width: 576px) {
            display: none;
          }
        }
      }
      .address-text {
        display: none;
        @media screen and (min-width: 576px) {
          display: inline;
        }
      }
    }
    &.full {
      .dropdown-wrapper {
        .network-name {
          display: inline;
          &.short {
            display: none;
          }
        }
        .address-text {
          display: inline;
        }
      }
    }
  }
}
</style>
