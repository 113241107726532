import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue } from 'bootstrap-vue';

import 'jsonld-vis/src/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './styles/index.scss';
import DkgService from './services/dkg';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from 'axios';
window.axios = axios;

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

window.EventBus = new Vue();

const useSSL = process.env.VUE_APP_NODE_USE_SSL === 'true';
const dkgInstance = new DkgService({
  endpoint: process.env.VUE_APP_NODE_URL,
  port: process.env.VUE_APP_NODE_PORT,
  useSSL: useSSL,
});

Vue.prototype.$dkgSerice = dkgInstance;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
