<template>
  <div class="identity-page-wrapper">
    <div :class="{ 'loader-wrapper': true, loading: loading }" v-if="!error">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="back">
      <back-button></back-button>
    </div>
    <div class="identity-page-header paragraph-small">
      Address: <span class="bold">{{ address }}</span>
    </div>
    <div class="identity-page-content">
      <div class="asset-info">
        <h4 class="paragraph-mini bold">Assets owned</h4>
        <p class="paragraph-mini">
          {{ owned.length }} in total ( {{ loading ? 'pending' : 'finished' }} )
        </p>
      </div>
      <div class="asset-list">
        <owner-asset-card :key="index" v-for="(asset, index) in owned" :nft-data="asset" />
      </div>
    </div>
  </div>
</template>

<script>
import OwnerAssetCard from '../components/OwnedAssetCard';
import BackButton from '@/components/BackButton';
import nftDataService from '../services/nftDataService';
export default {
  name: 'IdentityPage',
  components: { OwnerAssetCard, BackButton },
  props: {
    address: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      owned: [],
      loading: true,
      error: false,
      assets: [
        {
          id: 1,
          image: '/tracies/tracy123.png',
          title: "Obey Metcalfe's law shirt #53",
          description: 'Blockchain: Polygon',
          tags: ['Product'],
        },
        {
          id: 2,
          image: '/tracies/tracy123.png',
          title: "Obey Metcalfe's law shirt #53",
          description: 'Lorem ipsum dolor site ametLorem ipsum dolor site amet',
          tags: ['Product'],
        },
        {
          id: 3,
          image: '/tracies/tracy123.png',
          title: "Obey Metcalfe's law shirt #53",
          description:
            'Lorem ipsum dolor site ametLorem ipsum dolor site ametLorem ipsum dolor site amet',
          tags: ['Product'],
        },
        {
          id: 4,
          image: '/tracies/tracy123.png',
          title: "Obey Metcalfe's law shirt #53",
          description:
            'Lorem ipsum dolor site ametLorem ipsum dolor site ametLorem ipsum dolor site amet',
          tags: ['Product'],
        },
        {
          id: 5,
          image: '/tracies/tracy123.png',
          title: "Obey Metcalfe's law shirt #53",
          description: 'Lorem ipsum dolor site amet',
          tags: ['Product'],
        },
        {
          id: 6,
          image: '/tracies/tracy123.png',
          title: "Obey Metcalfe's law shirt #53",
          description: 'Lorem ipsum dolor site amet',
          tags: ['Product'],
        },
      ],
    };
  },
  created() {
    this.address = this.getIdFromQuery();
    this.getAddressAssets();
  },
  methods: {
    getIdFromQuery() {
      return this.$route.params.id;
    },
    getAddressAssets() {
      let config = {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_BACKEND_TOKEN}`,
        },
      };
      window.axios
        .get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/api/nft?w=${this.address}`, config)
        .then(async (response) => {
          let processed = 0;
          for (const row of response.data.bought) {
            const ownerAddress = await nftDataService.checkIfWalletIsStillOwner(row);
            if (ownerAddress.toLowerCase() === this.address.toLowerCase()) {
              this.loading = false;

              this.owned.push(row);
            }
            processed++;
            if (processed === response.data.bought.length) {
              this.loading = false;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.identity-page-wrapper {
  text-align: left;
  .bold {
    font-weight: 700;
  }
  .back {
    text-align: left;
    padding-top: 16px;
    margin-bottom: 32px;
  }
  .identity-page-header {
    padding-top: #{'min(80px, 5vh)'};
    padding-right: #{'min(70px, calc(5vw))'};
    color: var(--black-tertiary);
    .bold {
      color: var(--black);
      word-break: break-all;
    }
  }
  .identity-page-content {
    padding: #{'min(46px, calc(3vw))'};
    color: var(--black);
    padding-left: 0;
    .asset-list {
      margin-top: 1em;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 576px) {
        justify-content: center;
      }
      .nft-search-result-card {
        max-width: #{'min(calc(50% - 12px), 200px)'};
        &:not(:last-child) {
          margin-right: #{'min(20px, 2%)'};
        }
        margin-top: 20px;
      }
    }
  }
}

.loader-wrapper {
  min-height: calc(100vh - 107px);
  z-index: -1;
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--loading-background);
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;

  &.loading {
    z-index: 11;
    display: flex;
    overflow: hidden;
    opacity: 1;

    .spinner-border {
      opacity: 1;
    }
  }

  .spinner-border {
    margin: auto;
    margin-top: calc(50vh - 107px);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
}
</style>
