<template>
  <div :class="{ 'nft-component-wrapper': true, 'is-collection': isCollection }">
    <div class="row">
      <div :class="{ 'nft-media': true, 'is-collection': isCollection }">
        <a :href="'/asset/' + retrieveUai(rawObject.ual)">
          <img class="single-image" v-if="!isCollection && imageUrl" :src="imageUrl" />
        </a>
        <div class="collection row" v-if="isCollection && imageUrlSet">
          <img class="col-6" :key="img" v-for="img of getCollectionImages" :src="img" />
        </div>
      </div>
      <div class="nft-information">
        <a :href="'/asset/' + retrieveUai(rawObject.ual)" class="router-link-search-result">
          <h4 class="go-to-title">
            {{ title }}
          </h4>
        </a>

        <div class="collection-name">
          <!--          <p>{{ nftType }}</p>-->
          <p>Owner: {{ rawObject.owner }}</p>
          <p>UAI: {{ rawObject.ual }}</p>
          <p>Latest state: {{ rawObject.timestamp }}</p>
          <p>Blockchain: Polygon</p>
          <a class="link-url" :ref="linkUrl" v-if="collectionName">{{ collectionName }}</a>
        </div>
        <div class="tags" v-if="tags">
          <div :key="id + tag" v-for="tag of tags" class="tag">
            {{ tag }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NFTSearchComponent',
  props: {
    id: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    isCollection: {
      type: Boolean,
      default: null,
    },
    imageUrlSet: {
      type: Array,
      default: null,
    },
    collectionName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    nftType: {
      type: String,
      default: 'Collection',
    },
    linkUrl: {
      type: String,
      default: null,
    },
    tags: {
      type: Array,
      default: null,
    },
    rawObject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getCollectionImages() {
      return this.imageUrlSet ? this.imageUrlSet.slice(0, 4) : [];
    },
  },
  methods: {
    retrieveUai(ual) {
      ual = ual.split('/');
      return ual[ual.length - 1];
    },
    goToSinglePage(nftComponent) {
      if (nftComponent) {
        this.$router.push({
          name: 'AssetInfo',
          params: {
            inputAssetData: nftComponent,
            id: nftComponent.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nft-component-wrapper {
  &.is-collection {
    .row {
      --bs-gutter-x: 0;
    }
  }
  padding: 12px 0;
  .nft-media {
    display: inline-block;
    width: fit-content;
    @media screen and (max-width: 628px) {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
    img {
      border-radius: 8px;
    }
    .single-image {
      max-height: 96px;
      max-width: 96px;
    }
    &.is-collection {
      padding-right: 0;
      padding-left: 0;
      margin: 0;
      --bs-gutter-x: 0;
    }
    .collection {
      position: relative;
      height: 100%;
      width: 100%;
      --bs-gutter-x: 0;
      img {
        padding: 1px;
      }
    }
  }
  .nft-information {
    display: inline-block;
    width: fit-content;
    //margin-left: 1em;
    text-align: left;
    h4 {
      color: var(--black);
      &.go-to-title {
        //&:hover {
        //  text-decoration: underline;
        //  color: var(--accent-blue);
        //  cursor: pointer;
        //}
      }
    }
    .collection-name {
      //display: flex;
      p {
        padding: 0;
        margin: 0 0 7px 0;
        font-size: 14px;
        word-break: break-word;
        @media screen and (max-width: 1919px) {
          font-size: 12px;
        }
      }
      .link-url {
        margin-left: 1em;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .tags {
      display: flex;
      .tag {
        background-color: #ededed;
        border-radius: 100px;
        padding: 6px;
        font-size: 12px;
        &:not(last-child) {
          margin-right: 0.5em;
        }
      }
    }
  }
}

.router-link-search-result {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
</style>
