import { networkList } from '../../utils/lists';
import metamask from '../../services/metamask';

export default {
  state: {
    isConnected: false,
    connectedAddress: '',
    selectedNetwork: networkList[0],
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    CONNECT_VIA_METAMASK(state, newState) {
      metamask
        .connectToMetamask()
        .then((accounts) => {
          if (accounts) {
            state.isConnected = true;
            state.connectedAddress = accounts[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    LOGOUT(state) {
      state.isConnected = false;
      state.connectedAddress = null;
      metamask.disconnectFromMetamask();
    },
    NETWORK_CHANGE(state, newState) {
      state.selectedNetwork = networkList.find((e) => e.id === newState) ?? networkList[0];
      localStorage.setItem('selected_network', newState);
    },
  },
  actions: {
    connectWithMetamask(context) {
      context.commit('CONNECT_VIA_METAMASK');
      return true;
    },
    logout(context) {
      context.commit('LOGOUT');
    },
    changeNetwork(context, data) {
      context.commit('NETWORK_CHANGE', data);
    },
  },
  getters: {
    isConnected(state) {
      return state.isConnected;
    },
    connectedAddress(state) {
      return state.connectedAddress;
    },
    selectedNetwork(state) {
      return state.selectedNetwork;
    },
  },
};
