<template>
  <button class="btn btn-default connect-wallet-button" @click="$emit('click')">
    <span class="d-none d-sm-block">connect wallet</span>
    <img class="d-sm-none" src="@/assets/icons/user-icon.svg" />
  </button>
</template>

<script>
export default {
  name: 'ConnectWalletButton',
};
</script>

<style scoped lang="scss">
.connect-wallet-button {
  border: 1px solid var(--grey-300);
  border-radius: 100px;
  background-color: transparent;
  color: var(--grey-400);
  font-size: 12px;
  padding: 8px 16px;
}
</style>
