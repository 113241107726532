<template>
  <div class="search-bar-wrapper">
    <input
      v-on:keyup.enter="goToSearchPage()"
      :value="searchKeys"
      @change="(e) => onSearch(e.target.value.trim())"
      placeholder="Search"
      class="search-input"
      :disabled="searching"
    />
    <BButton @click="goToSearchPage()" type="info" class="search-button">
      <img src="@/assets/icons/search-icon.svg" />
    </BButton>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    isSearching: {
      type: Boolean,
      default: false,
    },
    inputKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchKeys: this.inputKey,
      searching: this.isSearching,
    };
  },
  watch: {
    isSearching(newVal) {
      this.searching = newVal;
    },
  },
  methods: {
    onSearch(value) {
      this.searchKeys = value.toLowerCase();
    },
    goToSearchPage() {
      this.$emit('onSearch', this.searchKeys.toLowerCase());
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar-wrapper {
  display: flex;
  * {
    margin-top: auto;
    margin-bottom: auto;
  }
  .search-input {
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 100px;
    height: 48px;
    width: 100%;
    padding: 16px;
    filter: drop-shadow(0px 4px 8px rgba(82, 97, 115, 0.18));
    &::before {
      content: 'LKLJL';
    }
  }
  .search-button {
    margin-left: 12px;
    padding: 10px;
    height: auto;
    background: #4744df;
    border-radius: 100px;
    filter: drop-shadow(0px 4px 8px rgba(82, 97, 115, 0.18));
    border: none;
  }
}
</style>
