<template>
  <div class="nft-search-result-card" @click="openAssetPage()">
    <div :class="{ 'loader-wrapper': true, loading: loading }" v-if="!error">
      <div class="spinner-border" role="status"></div>
    </div>
    <img class="nft-art" :src="imageURL" />
    <h4>{{ title }}</h4>
    <p class="description">{{ description }}</p>
    <tag-element color="blue" :tags="tags" />
  </div>
</template>

<script>
import TagElement from './TagElement';
export default {
  name: 'OwnerAssetCard',
  components: { TagElement },
  props: {
    nftData: {
      type: Object,
      default: null,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      trigger: 0,
      imageURL: 0,
      title: 0,
      description: 0,
      loading: true,
    };
  },
  watch: {
    nftData: {
      handler: function () {
        this.trigger++;
      },
      deep: true,
    },
  },
  mounted() {
    this.title = this.nftData.tokenName;
    this.description = `Symbol: ${this.nftData.tokenSymbol}`;
    this.imageURL = '';
    this.tags.push('NFT');
    setTimeout(() => {
      this.resolveAsset();
    }, 1500);
  },
  methods: {
    resolveAsset() {
      window.axios
        .get(
          `https://athena-develop-backend.origintrail.io/api/token-resolver/${this.nftData.tokenID}`,
        )
        // eslint-disable-next-line no-unused-vars
        .then(async (response) => {
          this.loading = false;
          if (response.data.properties.name) {
            this.title = response.data.properties.name;
          }
          if (response.data.properties.description) {
            this.description = response.data.properties.description;
          }
          if (response.data.properties.image) {
            this.imageURL = response.data.properties.image;
          }
          this.trigger++;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goToSinglePage(nftComponent) {
      if (nftComponent) {
        this.$router.push({
          name: 'AssetInfo',
          params: {
            inputAssetData: nftComponent,
            id: nftComponent.id,
          },
        });
      }
    },
    openAssetPage() {
      window.open(`/asset/${this.nftData.tokenID}`, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.nft-search-result-card {
  position: relative;
  padding: 16px;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    padding: 12px;
  }
  border-radius: 8px;
  background-color: var(--grey-50);
  min-width: 230px;
  display: flex;
  min-height: 370px;
  flex-direction: column;
  h4 {
    margin-top: 0.5em;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: var(--black);
  }
  .nft-art {
    border-radius: 8px;
    width: 100%;
  }
  .tags {
    margin-top: auto;
    font-weight: 500;
  }
}

.loader-wrapper {
  z-index: -1;
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--loading-background);
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;

  &.loading {
    z-index: 11;
    display: flex;
    overflow: hidden;
    opacity: 1;

    .spinner-border {
      opacity: 1;
    }
  }

  .spinner-border {
    margin: auto;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
}
</style>
