<template>
  <div class="graph-preview">
    <div class="switcher">
      <div class="tab left" :class="{ active: active === 'left' }" @click="changeActive('left')">
        Graph view
      </div>
      <div class="tab right" :class="{ active: active === 'right' }" @click="changeActive('right')">
        JSON-LD view
      </div>
    </div>
    <div class="graph">
      <div v-if="active === 'left'">
        <div id="graph"></div>
      </div>
      <div v-if="active === 'right'">
        <textarea v-model="prettyJson" resi readonly class="code-preview"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import jsonldVis from 'jsonld-vis';
import d3 from 'd3';
jsonldVis(d3);

export default {
  name: 'GraphPreview',
  props: {
    jsonLd: {
      default: () => {},
    },
  },
  data() {
    return {
      active: 'left',
    };
  },
  computed: {
    prettyJson() {
      if (!Object.keys(this.jsonLd).length) {
        return '';
      }

      return JSON.stringify(this.jsonLd, null, '    ');
    },
  },
  mounted() {
    this.renderGraphTree();
  },
  methods: {
    changeActive(side) {
      this.active = side;
      if (side === 'left') {
        this.$nextTick(() => {
          this.renderGraphTree();
        });
      }
    },
    renderGraphTree() {
      d3.jsonldVis(this.jsonLd, '#graph', {
        scalingFactor: 100000000,
        h: 400,
        maxLabelWidth: 250,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.graph-preview {
  font-family: Poppins;
  .switcher {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 40px;

    .tab {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      transition: 0.2s;
      color: var(--accent-blue);
      border: 1px solid var(--grey-100);
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      cursor: pointer;
      padding: 10px 15px;

      @media screen and (max-width: 577px) {
        font-size: 14px;
        line-height: 20px;
      }
      @media screen and (min-width: 578px) {
        font-size: 16px;
        line-height: 20px;
      }
      &.left {
        border-right: none;
        border-radius: 8px 0px 0px 8px;
      }
      &.right {
        border-radius: 0 8px 8px 0;
      }
      &.active {
        color: white;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--blue);
      }
    }
  }
}

.code-preview {
  font-family: monospace;
  border: 1px solid var(--grey-100);
  width: 100%;
  height: 240px;
  max-height: 400px;
  resize: none;
  overflow-x: auto;
  white-space: pre;
  border-radius: 2px;
  &:focus-visible {
    outline: none;
  }
}

#graph {
  width: 100%;
  max-height: 400px;
  overflow: auto;
}
</style>
