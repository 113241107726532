<template>
  <div class="footer-wrapper">
    <div class="row">
      <div class="terms-wrapper">
        <div class="footer-spans-wrapper">
          <span class="paragraph-mini">© 2013-2022.</span>
          <span class="paragraph-mini">OriginTrail. All rights reserved. </span>
        </div>
        <div class="footer-links-wrapper">
          <a
            href="https://origintrail.io/terms-and-conditions"
            target="_blank"
            class="footer-link-terms"
            >Terms and Conditions
          </a>
          <a href="https://origintrail.io/privacy" target="_blank" class="footer-link-terms"
            >Privacy policy
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
.footer-wrapper {
  bottom: 0;
  width: 100%;
  display: block;
  padding-bottom: 40px;
  @media screen and (max-width: 768px) {
    //padding: 72px 15px 48px 15px;
    display: inline-block;
    span {
      margin-top: 15px;
      font-size: 14px;
    }

    .footer-link-terms {
      margin-top: 14px;
      font-size: 14px;
      display: inline;
    }
  }

  .social-icons-wrapper {
    width: 100%;
    text-align: center;
  }

  @media screen and (min-width: 769px) {
    span {
      margin-right: 20px;
      font-size: 14px;
    }

    .footer-link-terms {
      margin-right: 20px;
      font-size: 14px;
      display: inline;
    }
  }

  .terms-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    @media screen and (min-width: 769px) {
      .footer-links-wrapper,
      .footer-spans-wrapper {
        display: inline;
      }
    }

    @media screen and (max-width: 768px) {
      .footer-links-wrapper,
      .footer-spans-wrapper {
        display: block;
      }
    }
  }

  .icons {
    margin: 50px auto;
    @media screen and (max-width: 450px) {
      margin: 50px 5px;

      .icon {
        margin: 0 5px;
      }
    }
    .icon {
      display: inline;
      margin: 0 10px;
    }
  }
}

#footer-whitepaper-button.btn-link,
#footer-origin-trail-button.btn-link {
  margin: 11px 15px 15px 11px;

  @media screen and (max-width: 450px) {
    margin: 11px 5px 15px 5px;
  }
  .ot-button {
    width: auto;
  }
}
</style>
