export const networkList = [
  {
    title: 'Testnet',
    id: '0x13881',
  },
  {
    title: 'Mainnet COMING SOON',
    id: '0x89',
    disabled: true,
  },
];
