<template>
  <div class="nft-search-result-card">
    <img class="nft-art" :src="imageUrl" />
    <h4>{{ title }}</h4>
    <a @click="goToSinglePage(rawObject)" class="collection-link">
      View detail
      <span class="ml-2"><img src="@/assets/icons/next-caret.svg" /></span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'NFTSearchResultCards',
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    rawObject: {
      type: Object,
      default: null,
    },
  },
  methods: {
    goToSinglePage(nftComponent) {
      if (nftComponent) {
        this.$router.push({
          name: 'AssetInfo',
          params: {
            inputAssetData: nftComponent,
            id: nftComponent.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nft-search-result-card {
  padding: 8px;
  border-radius: 8px;
  background-color: var(--grey-50);
  min-width: 130px;
  h4 {
    margin-top: 0.5em;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: var(--black);
  }
  .nft-art {
    border-radius: 8px;
    width: 100%;
    max-width: 96px;
    height: 96px;
  }
  .collection-link {
    margin-top: 1em;
    font-size: 14px;
    color: var(--lavender);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
