<template>
  <div class="top-asset-wrapper">
    <div class="tags" v-if="tags">
      <div :key="id + tag" v-for="tag of tags" class="tag">
        {{ tag }}
      </div>
    </div>
    <div class="nft-data">
      <div class="profile-collection" v-if="imageUrls">
        <img :key="image" class="nft-art" v-for="image of imageUrls" :src="image" />
      </div>
      <h4>{{ title }}</h4>
      <p class="description">{{ description }}</p>
      <div class="item">
        <div class="label">Author</div>
        <div class="value">
          {{ author }}
        </div>
      </div>
      <div class="item">
        <div class="label">Release date</div>
        <div class="value">
          {{ getReleaseDate(releaseDate) }}
        </div>
      </div>
      <a href="https://origintrail.io" class="collection-link">
        See collection
        <span class="ml-2"><img src="@/assets/icons/next-caret.svg" /></span>
      </a>
    </div>
  </div>
</template>

<script>
import dayJs from 'dayjs';
export default {
  name: 'TopAssetComponent',
  props: {
    id: {
      type: String,
      default: null,
    },
    tags: {
      type: Array,
      default: null,
    },
    imageUrls: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    author: {
      type: String,
      default: null,
    },
    releaseDate: {
      type: String,
      default: null,
    },
  },
  methods: {
    getReleaseDate(date) {
      return dayJs(date).format('MMM D, YYYY');
    },
  },
};
</script>

<style scoped lang="scss">
.top-asset-wrapper {
  color: var(--black);
  padding: 16px;
  text-align: left;
  border-radius: 8px;
  background-color: var(--grey-50);
  .tags {
    margin: 1em 0;
    display: flex;
    .tag {
      color: white;
      background-color: var(--lavender);
      border-radius: 100px;
      padding: 6px;
      font-size: 14px;
      font-weight: 500;
      &:not(last-child) {
        margin-right: 0.5em;
      }
    }
  }
  .nft-data {
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    }
    .description {
      margin-bottom: 1.2em;
      color: var(--black-secondary);
    }
    .item {
      display: flex;
      margin: 1em 0;
      .label {
        color: var(--black-tertiary);
        font-size: 14px;
      }
      .value {
        margin-left: 1em;
        color: var(--black);
        font-size: 14px;
      }
    }
    .profile-collection {
      margin-bottom: 1em;
      display: flex;
      overflow-x: auto;
      .nft-art {
        border-radius: 8px;
        height: #{'min(10em, 160px)'};
        width: #{'min(10em, 160px)'};
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }
    .collection-link {
      font-size: 14px;
      color: var(--lavender);
      text-decoration: none;
    }
  }
}
</style>
