<template>
  <div class="search-result-wrapper">
    <error-overlay-component v-if="error" :title="errorTitle" :description="errorDescription" />
    <error-overlay-component
      v-if="noResultsFound"
      :title="errorTitle"
      :description="errorDescription"
    />
    <div :class="{ 'loader-wrapper': true, loading: isLoading }" v-if="!error && !noResultsFound">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="search-results row" v-if="!error && !noResultsFound">
      <div class="col-lg-8 col-md-10 col-12 nft-results offset-lg-2 offset-md-1 offset-0">
        <div class="search-result-counter offset-md-4" v-if="keyword !== null">
          Searching for <b>{{ keyword }}</b> (
          {{ searchingFinished ? (acquiringData ? 'in progress' : 'finished') : 'pending' }} )
          <span class="mx-4 progress-spinner" v-if="acquiringData && !isLoading"
            ><div class="spinner-border" role="status"></div
          ></span>
        </div>
        <div class="search-result-counter offset-md-4" v-if="showResultCount">
          {{ searchResultsCount }} results found | Nodes responded: {{ nodesResponded.length }}
        </div>
        <n-f-t-search-component
          :key="nft.id"
          v-for="nft of pagedTableData"
          :image-url="nft.image"
          :image-url-set="nft.images"
          :is-collection="nft.isCollection"
          :title="nft.name"
          :collection-name="nft.collectionName"
          :id="nft.id"
          :link-url="nft.url"
          :nft-type="nft.isCollection ? 'Collection' : 'Art'"
          :tags="nft.tags"
          :raw-object="nft"
        />
        <text-search-result-component
          :key="textResult.id"
          v-for="textResult of textSearchResults"
          :title="textResult.title"
          :description="textResult.description"
          :url="textResult.url"
          :community-url="textResult.communityUrl"
          :get-in-touch-url="textResult.getInTouchUrl"
          :tech-url="textResult.techUrl"
          :urls="textResult.urls"
        />
        <el-pagination
          class="mt-5 mb-5"
          id="search-results-pagination"
          v-if="showResultCount && searchResults.length > 0"
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="searchResults.length"
          @current-change="setPage"
        >
        </el-pagination>
      </div>
      <!--      <div class="col-4 nft-top-asset">-->
      <!--        <div class="top-assets" v-if="topAsset">-->
      <!--          <top-asset-component-->
      <!--            :description="topAsset.description"-->
      <!--            :title="topAsset.title"-->
      <!--            :tags="topAsset.tags"-->
      <!--            :id="topAsset.id"-->
      <!--            :author="topAsset.author"-->
      <!--            :image-urls="topAsset.images"-->
      <!--            :release-date="topAsset.releaseDate"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div v-if="similarResults.length > 0">-->
      <!--          <p class="similar-results-title">Similar results</p>-->
      <!--          <div class="similar-result">-->
      <!--            <n-f-t-search-result-cards-->
      <!--              :key="similar.id"-->
      <!--              v-for="similar of similarResults"-->
      <!--              :title="similar.title"-->
      <!--              :url="similar.url"-->
      <!--              :image-url="similar.image"-->
      <!--              :raw-object="similar.rawObject"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import NFTSearchComponent from '../components/NFTSearchComponent';
import TextSearchResultComponent from '../components/TextSearchResultComponent';
import TopAssetComponent from '../components/TopAssetComponent';
import NFTSearchResultCards from '../components/NFTSearchResultCards';
// import { getPropertyValue, metaParser, typeParser } from '../services/typeParsers';
import ErrorOverlayComponent from '../components/ErrorOverlayComponent';
// eslint-disable-next-line no-unused-vars
import { getProperty, getPropertyValue } from '../services/typeParsers';

export default {
  name: 'SearchResultPage',
  components: {
    ErrorOverlayComponent,
    // eslint-disable-next-line vue/no-unused-components
    NFTSearchResultCards,
    // eslint-disable-next-line vue/no-unused-components
    TopAssetComponent,
    TextSearchResultComponent,
    NFTSearchComponent,
  },
  data() {
    return {
      searchResultsCount: 0,
      nodesResponded: [],
      isLoading: false,
      showResultCount: false,
      searchResults: [],
      textSearchResults: null,
      topAsset: null,
      similarResults: [],
      error: false,
      errorTitle: null,
      errorDescription: null,
      pageSetup: false,
      keyword: null,
      searchingFinished: false,
      page: 1,
      pageSize: 5,
      noResultsFound: false,
      acquiringData: false,
    };
  },
  computed: {
    searchCalled() {
      return this.$store.getters.getSearchAction;
    },
    searching() {
      return this.$store.getters.isSearching;
    },
    pagedTableData() {
      return this.searchResults.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page,
      );
    },
  },
  watch: {
    searchCalled(newValue) {
      if (newValue) {
        if (this.pageSetup && !this.searching) {
          this.resetErrorPages();
          this.keyword = this.$store.getters.getSearchKeyword;
          this.error = false;
          this.isLoading = true;
          this.$store.dispatch('toggleSearching');
          this.search(this.keyword)
            .catch(() => {
              this.error = true;
            })
            .finally(() => {
              this.isLoading = false;
              this.$store.dispatch('toggleSearching');
            });
        }
      }
    },
  },
  created() {
    if (this.getKeyword()) {
      this.resetErrorPages();
      this.isLoading = true;
      this.search(this.getKeyword())
        .finally(() => {
          this.showResultCount = true;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pageSetup = true;
        });
    } else {
      this.errorTitle = 'Nothing to search for';
      this.errorDescription = 'Use the search field above to type your search keywords';
      this.error = true;
      this.pageSetup = true;
    }
    this.keyword = this.getKeyword();
  },
  methods: {
    setPage(val) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.page = val;
    },
    getKeyword() {
      return this.$route.query.key;
    },
    search(keyword) {
      this.searchResults = [];
      this.searchResultsCount = this.searchResults.length;
      this.showResultCount = false;
      this.searchingFinished = false;
      return this.$dkgSerice
        .searchAssets(keyword, (data) => {
          if (!this.acquiringData) this.acquiringData = true;
          // the moment the first result has datasets in it. we switch to the inProgress spinner
          if (data.itemListElement?.length > 0) {
            this.showResultCount = true;
            this.searchingFinished = true;
            this.isLoading = false;
          }
          for (const row of data.itemListElement.slice(this.searchResultsCount)) {
            this.nodesResponded = this.nodesResponded.concat(row.nodes);
            this.nodesResponded = this.nodesResponded.filter(function (value, index, array) {
              return array.indexOf(value) === index;
            });

            let template = {
              type: row.result['@type'],
              name: row.result.name,
              blockchain: 'polygon',
              description: row.result.description,
              image: row.result.image,
              isCollection: false,
              id: row.result['@id'],
              ual: row.result['@id'],
              timestamp: row.result.timestamp,
              owner: row.issuers[0],
            };
            this.searchResults.push(template);
            this.searchResultsCount++;
          }
        })
        .then(async (data) => {
          this.acquiringData = false;
          this.isLoading = false;
          if (data.itemListElement?.length === 0) {
            this.showNoResultsFound();
            return;
          }
        });
    },
    wait(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    randomTime(maximum, minimum) {
      return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    },
    showNoResultsFound() {
      this.noResultsFound = true;
      this.errorTitle = 'No results found';
      this.errorDescription =
        "your query didnt' return any results, our nodes might be overloaded. Please refresh the page and try again";
    },
    resetErrorPages() {
      this.error = false;
      this.noResultsFound = false;
    },
  },
};
</script>

<style scoped lang="scss">
.search-result-wrapper {
  position: relative;
  min-height: calc(100vh - 107px);

  .loader-wrapper {
    min-height: calc(100vh - 107px);
    z-index: -1;
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--loading-background);
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;

    &.loading {
      z-index: 11;
      display: flex;
      overflow: hidden;
      opacity: 1;

      .spinner-border {
        opacity: 1;
      }
    }

    .spinner-border {
      margin: auto;
      margin-top: calc(50vh - 107px);
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
  }

  text-align: left;

  .search-result-counter {
    margin: 2em 0;
    text-align: left;
    .progress-spinner {
      .spinner-border {
        height: 1.3rem;
        width: 1.3rem;
      }
    }
  }

  .search-results {
    padding: 12px;

    ::v-deep .nft-component-wrapper {
      border-bottom: 1px solid #dfdfdf;
    }

    .nft-results {
      .test-search-result-component-wrapper {
        margin: 1.5em 0;
      }
    }

    .nft-top-asset {
      padding: 24px;
      padding-left: 1em;
    }
  }

  .similar-results-title {
    margin: 1.8em 0 1.2em 2em;
    color: var(--black-secondary);
  }

  .similar-result {
    display: flex;
    overflow-x: auto;

    .nft-search-result-card {
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
}
</style>
