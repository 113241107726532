<template>
  <div id="triple-graph"></div>
</template>

<script>
import d3 from 'd3';
export default {
  name: 'TripleGraph',
  props: {
    triples: {
      default: () => [],
    },
  },
  watch: {
    triples(val) {
      if (val) this.createGraph();
    },
  },
  methods: {
    createGraph() {
      const container = document.querySelector('.triple-graph-container').getBoundingClientRect();
      const svg = d3
        .select('#triple-graph')
        .append('svg')
        .attr('width', container.width)
        .attr('height', container.height);
      const force = d3.layout.force().size([container.width, container.height]);
      const graph = this.triplesToGraph(svg, this.triples);

      this.update(svg, force, graph);
    },
    triplesToGraph(svg, triples) {
      svg.html('');
      //Graph
      const graph = { nodes: [], links: [] };

      //Initial Graph from triples
      for (const triple of triples) {
        const subjId = triple.subject;
        const predId = triple.predicate;
        const objId = triple.object;

        let subjNode = this.filterNodesById(graph.nodes, subjId)[0];
        let objNode = this.filterNodesById(graph.nodes, objId)[0];

        if (subjNode == null) {
          subjNode = { id: subjId, label: subjId, weight: 1 };
          graph.nodes.push(subjNode);
        }

        if (objNode == null) {
          objNode = { id: objId, label: objId, weight: 1 };
          graph.nodes.push(objNode);
        }

        graph.links.push({ source: subjNode, target: objNode, predicate: predId, weight: 1 });
      }

      return graph;
    },
    filterNodesById(nodes, id) {
      return nodes.filter((n) => {
        return n.id === id;
      });
    },
    update(svg, force, graph) {
      // ==================== Add Marker ====================
      svg
        .append('svg:defs')
        .selectAll('marker')
        .data(['end'])
        .enter()
        .append('svg:marker')
        .attr('id', String)
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 30)
        .attr('refY', -0.5)
        .attr('markerWidth', 5)
        .attr('markerHeight', 5)
        .attr('orient', 'auto')
        .append('svg:polyline')
        .attr('points', '0,-5 10,0 0,5');

      // ==================== Add Links ====================
      const links = svg
        .selectAll('.link')
        .data(graph.links)
        .enter()
        .append('line')
        .attr('marker-end', 'url(#end)')
        .attr('class', 'link')
        .attr('stroke-width', 1); //links
      // ==================== Add Link Names =====================
      const linkTexts = svg
        .selectAll('.link-text')
        .data(graph.links)
        .enter()
        .append('text')
        .attr('class', 'link-text')
        .style('font-size', '10px')

        .text(function (d) {
          let predicate = d.predicate.split('//')[1];
          return predicate.length > 26 ? predicate.slice(0, 26) + '...' : predicate;
        });
      //linkTexts.append("title")
      //		.text(function(d) { return d.predicate; });

      // ==================== Add Link Names =====================
      const nodeTexts = svg
        .selectAll('.node-text')
        .data(graph.nodes)
        .enter()
        .append('text')
        .attr('class', 'node-text')
        .style('font-size', '10px')
        .text(function (d) {
          let label = d.label.split('//');
          label = label.length === 1 ? label[0] : label[1];
          return label.length > 14 ? label.slice(0, 14) + '...' : label;
        });
      //nodeTexts.append("title")
      //		.text(function(d) { return d.label; });

      // ==================== Add Node =====================
      const nodes = svg
        .selectAll('.node')
        .data(graph.nodes)
        .enter()
        .append('circle')
        .attr('class', 'node')
        .attr('r', 8)
        .call(force.drag); //nodes
      // ==================== Force ====================
      force.on('tick', function () {
        nodes
          .attr('cx', function (d) {
            return d.x;
          })
          .attr('cy', function (d) {
            return d.y;
          });

        links
          .attr('x1', function (d) {
            return d.source.x;
          })
          .attr('y1', function (d) {
            return d.source.y;
          })
          .attr('x2', function (d) {
            return d.target.x;
          })
          .attr('y2', function (d) {
            return d.target.y;
          });

        nodeTexts
          .attr('x', function (d) {
            return d.x + 12;
          })
          .attr('y', function (d) {
            return d.y + 3;
          });

        linkTexts
          .attr('x', function (d) {
            return 4 + (d.source.x + d.target.x) / 2;
          })
          .attr('y', function (d) {
            return 4 + (d.source.y + d.target.y) / 2;
          });
      });

      // ==================== Run ====================
      force.nodes(graph.nodes).links(graph.links).charge(-500).linkDistance(100).start();
    },
  },
};
</script>

<style lang="scss">
.triple-graph-container {
  #triple-graph {
    svg {
      .node-text {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60px;
      }

      text {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60px;
      }
    }
  }

  .link-text {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
    color: red;
  }
}
</style>
