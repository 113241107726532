<template>
  <div class="error-component-wrapper">
    <div class="error-content-wrapper">
      <h1 class="error-title">
        {{ errorTitle }}
      </h1>
      <h4>
        {{ errorDescription }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorOverlayComponent',
  props: {
    title: {
      type: String,
      default: 'An error has occurred',
    },
    description: {
      type: String,
      default:
        'An error has occurred during your request, our nodes might be overloaded. Try refreshing the page and retry again.',
    },
  },
  computed: {
    errorTitle() {
      return this.title ?? 'An error has occurred';
    },
    errorDescription() {
      return (
        this.description ??
        'An error has occurred during your request, our nodes might be overloaded. Try refreshing the page and retry again.'
      );
    },
  },
};
</script>

<style scoped lang="scss">
.error-component-wrapper {
  width: 100%;
  height: 100%;
  min-height: var(--minimal-main-section-height);
  display: flex;

  .error-content-wrapper {
    text-align: center;
    margin: auto;
    max-width: 800px;
    padding: 2em;

    h1 {
      color: rgba(#404040, 0.4);
    }

    h4 {
      margin-top: 1em;
      color: rgba(#404040, 0.35);
    }
  }
}
</style>
