import DKG from 'dkg.js';
import defaultConfig from './config.json';

let instance;

class DkgService {
  constructor(config) {
    this.instance = new DKG({
      ...defaultConfig.dkg.default,
      ...config,
    });

    if (!instance) {
      instance = this;
    }

    return instance;
  }

  getDkgInfo() {
    return this.instance.nodeInfo();
  }

  createAsset(asset, options) {
    return this.instance.assets.create(asset, {
      ...defaultConfig.options.asset.default,
      ...(options ?? {}),
    });
  }

  updateAsset(asset, ual, options) {
    return this.instance.assets.update(asset, ual, {
      ...(options ?? {}),
    });
  }

  // eslint-disable-next-line no-unused-vars
  search(keyword, { assertions, numberOfResults, searchCallback } = {}) {
    return this.instance.search(
      {
        query: keyword,
        resultType: assertions ? 'assertions' : 'entities',
        frequency: 2,
        timeoutInSeconds: 60,
      },
      (results) => {
        if (searchCallback) {
          searchCallback(results);
        }
      },
    );
  }

  resolveAnAsset(id) {
    return this.instance.assets.get(id);
  }

  resolveAsset(id) {
    return this.instance.resolve({ ids: [id] });
  }

  //TODO add pagination options
  searchAssets(keyword, cb) {
    return this.search(keyword, { searchCallback: cb });
  }

  query(query, type) {
    return this.instance.query({ query, type });
  }
}

export default DkgService;
