<template>
  <div class="single-asset-view-wrapper">
    <div :class="{ 'loader-wrapper': true, loading: isLoading }">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="back">
      <back-button></back-button>
    </div>
    <div class="no-results-found" v-if="!assetData && !isLoading">No results found</div>
    <div class="main-wrapper row" v-if="assetData">
      <div class="col-sm-12 col-md-12 col-lg-3 asset-data-section">
        <div
          v-if="assetData.image && !imageError"
          :class="{ 'nft-media': true, 'is-collection': isCollection }"
        >
          <img class="single-image" @error="imageError = true" :src="assetData.image" />
          <div class="collection row" v-if="isCollection && assetData.collectionImages">
            <img
              class="col-6"
              :key="img"
              v-for="img of getPreviewImages(assetData.collectionImages)"
              :src="img"
            />
          </div>
        </div>
        <div class="nft-data" v-if="assetData">
          <h4 class="mb-4 asset-info-headline">Asset</h4>

          <div class="info-item">
            <div class="name">Name</div>
            <div class="value">{{ assetData.name }}</div>
          </div>
          <div class="info-item">
            <div class="name">Description</div>
            <div class="value">{{ assetData.description }}</div>
          </div>
          <div class="info-item">
            <div class="name">Type</div>
            <div class="value">{{ assetData.type }}</div>
          </div>
          <!--          <div class="info-item">-->
          <!--            <div class="name">Contract</div>-->
          <!--            <div class="value">{{ assetData.contract }}</div>-->
          <!--          </div>-->
          <div class="info-item">
            <div class="name">Blockchain</div>
            <div class="value">{{ assetData.blockchain }}</div>
          </div>
          <div class="info-item">
            <div class="name">Issuer</div>
            <div class="value">
              <a class="link-text" :href="`/identity/${assetData.issuer}`">{{
                assetData.issuer
              }}</a>
            </div>
          </div>
          <div class="info-item">
            <div class="name">Schema</div>
            <div class="value">{{ assetData.schema }}</div>
          </div>
          <template v-for="(value, field) in customProperties">
            <div :key="field" class="info-item">
              <div class="name">{{ field | capitalize }}</div>
              <div class="value">
                <div v-if="!isLink(value)">{{ value }}</div>
                <a class="link-text" v-if="isLink(value)" :href="`/asset/${retrieveUai(value)}`">{{
                  value
                }}</a>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-9 asset-details" v-if="assetData">
        <div class="headline">
          <div class="title">
            <div class="label">Asset:</div>
            <div class="value">{{ assetData.name }}</div>
            <a
              :href="`https://testnets.opensea.io/assets/mumbai/0x5A1D5e2d0E76fe46f80189203f4fb730124534CF/${getIdFromQuery()}`"
              target="_blank"
              class="buy-btn"
              >Buy</a
            >
          </div>
          <div class="title">
            <div class="ual-label">UAL:</div>
            <div class="ual-value">{{ assetData.id }}</div>
          </div>
          <div class="title small headline-separator">
            <div class="label">Owner:</div>
            <div class="value">
              <a class="link-text" :href="`/identity/${assetData.issuer}`">{{ owner }}</a>
            </div>
          </div>
          <div class="title small">
            <div class="label">UAI:</div>
            <div class="value">{{ this.getIdFromQuery() }}</div>
          </div>
        </div>
        <div class="asset-feed">
          <div class="title">Asset feed</div>
          <template v-for="tx in getAssetTxs">
            <div :key="tx.timestamp" class="transaction">
              <img class="icon" src="@/assets/images/tx-sign.svg" alt="tx" />
              <div class="tx-info">
                <div class="tx" v-html="tx.html"></div>
                <div class="timestamp">{{ tx.timestamp }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="graph-section">
          <graph-preview :jsonLd="jsonLd"></graph-preview>
        </div>
        <div class="sdk-section">
          <div class="title">Want to use this asset in your app?</div>
          <a
            class="link-text"
            href="https://docs.origintrail.io/layer-2-dkg-v6-beta/dkg-sdk"
            target="_blank"
            >Head over here to explore OriginTrail SDKs here.</a
          >
        </div>
        <div class="asset-state-graph">
          <div class="title">Asset state graph</div>
          <div class="triple-graph-container">
            <div class="loading-msg" v-if="!triples">Rendering triples visualization...</div>
            <triple-graph :triples="triples"></triple-graph>
          </div>
        </div>
        <div class="validation-section row mb-5">
          <!--          <div class='col-sm-12 col-md-12 col-lg-6 assertions'>-->
          <!--            <div class='title'>DKG Assertions</div>-->
          <!--            <div class='assertions'>-->
          <!--              <template v-for='assertion in assertions'>-->
          <!--                <div-->
          <!--                  :class='{ selected: selectedAssertion.id === assertion.id }'-->
          <!--                  :key='assertion.id'-->
          <!--                  @click='selectedAssertion = assertion'-->
          <!--                  class='assertion'-->
          <!--                >-->
          <!--                  {{ assertion.id }}-->
          <!--                </div>-->
          <!--              </template>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-sm-12 col-md-12 col-lg-6 validation">
            <div class="title">Assertion verification</div>
            <validation-component
              v-if="selectedAssertion"
              :identifier="assetData.id"
              selected-lang="english"
              :languages="languages"
              :blockchain-utilities="blockchainUtilities"
              :exported-dataset="jsonLd"
              :verifiable-claims="verifiableClaims.default"
              :unpublished="false"
            ></validation-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nftDataService from '../services/nftDataService';
import dayjs from 'dayjs';
import GraphPreview from '@/components/GraphPreview';
import ValidationComponent from '../components/ValidationComponent/ValidationComponent';

import * as VerifiableClaims from '../components/ValidationComponent/verifiable-claims/verifiable-claims-nft.json';
import Languages from '../components/ValidationComponent/languages';
import BlockchainUtilities from '../components/ValidationComponent/blockchain-utilities';
import TripleGraph from '@/components/TripleGraph';
import BackButton from '@/components/BackButton';

export default {
  name: 'Asset',
  components: { GraphPreview, TripleGraph, BackButton, ValidationComponent },
  filters: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  props: {
    inputAssetData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isCollection: false,
      assetData: null,
      isLoading: true,
      jsonLd: null,
      verifiableClaims: VerifiableClaims,
      languages: Languages.getLanguages(),
      blockchainUtilities: BlockchainUtilities,
      triples: null,
      imageError: false,
      assertions: null,
      selectedAssertion: null,
      assetTxs: [],
      owner: '',
    };
  },
  computed: {
    getAssetTxs() {
      const defaultValue = '0x0000000000000000000000000000000000000000';
      return this.assetTxs.map((e) => {
        const timestamp = dayjs(e.timeStamp * 1000).format('MMM DD YYYY, HH:mm');

        if (e.from === defaultValue) {
          return {
            html: `Created by <b>${e.to}</b>`,
            timestamp,
          };
        } else {
          return {
            html: `Transferred by <b>${e.from}</b> to <b>${e.to}</b> `,
            timestamp,
          };
        }
      });
    },
    customProperties() {
      const common = ['@context', 'id', '@id', 'image', 'name', 'type'];
      const custom = {};
      for (const field in this.jsonLd) {
        if (!common.includes(field) && typeof this.jsonLd[field] !== 'object') {
          custom[field] = this.jsonLd[field];
        }
      }

      return custom;
    },
  },
  async created() {
    this.fetchNftHistory();
    this.fetchTokenOwner();
    await this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      if (this.inputAssetData) {
        this.assetData = this.inputAssetData;
        this.isLoading = false;
      } else {
        if (this.getIdFromQuery()) {
          this.resolveAsset(this.getIdFromQuery())
            .then(() => {
              this.fetchTriplesGraphTriples();
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      }
    },
    getPreviewImages(imageList) {
      return imageList ? imageList.slice(0, 4) : [];
    },
    getIdFromQuery() {
      return this.$route.params.id;
    },
    isLink(string) {
      return string.includes(':') || string.includes('//');
    },
    resolveAsset(assetId) {
      const prefix = 'dkg://did.polygon.testnet.0xDbF8e9d36A73Cd890eF093b3fC38a852d878B65a/';

      return this.$dkgSerice.resolveAsset(prefix + assetId).then(({ data }) => {
        if (!data.length) {
          return;
        }
        const asset = data[0].result;

        this.jsonLd = asset.data;
        this.assertions = asset.assertions;
        this.selectedAssertion = asset.assertions[0];

        this.assetData = {
          type: asset.metadata.type,
          name: asset.metadata.name,
          blockchain: 'polygon:testnet',
          issuer: asset.metadata.issuer,
          description: asset.metadata.description,
          image: asset.data.image,
          isCollection: false,
          id: asset.data['@id'],
          schema: asset.data['@context'],
        };
      });
    },

    parseName(property) {
      let parsed = property.filter(function (el) {
        return el.name === 'name';
      });
      return parsed[0].value;
    },
    async fetchTriplesGraphTriples() {
      const query = `PREFIX schema: <http://schema.org/>
SELECT ?subject ?predicate ?object FROM NAMED <did:dkg:${this.selectedAssertion.id}>
{ GRAPH ?g { ?subject ?predicate ?object } }
`;

      let { data } = await this.$dkgSerice.query(query, 'select');

      data = data.results.bindings;
      const triples = [];

      for (const d of data) {
        const obj = {};
        for (const field in d) {
          obj[field] = d[field].value;
        }
        triples.push(obj);
      }

      this.triples = triples;
    },
    retrieveUai(ual) {
      ual = ual.split('/');
      return ual[ual.length - 1];
    },
    async fetchNftHistory() {
      let config = {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_BACKEND_TOKEN}`,
        },
      };

      const { data } = await window.axios.get(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/api/contract-history`,
        config,
      );

      const relatedTxs = [];
      for (const tx of data) {
        if (tx.tokenID === this.getIdFromQuery()) {
          relatedTxs.push(tx);
        }
      }

      this.assetTxs = relatedTxs;
    },
    async fetchTokenOwner() {
      const res = await nftDataService.checkIfWalletIsStillOwner({
        tokenID: this.getIdFromQuery(),
        contractAddress: '0x5A1D5e2d0E76fe46f80189203f4fb730124534CF',
      });
      this.owner = res;
    },
  },
};
</script>

<style scoped lang="scss">
.single-asset-view-wrapper {
  min-height: 100px;

  .loader-wrapper {
    z-index: -1;
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--loading-background);
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;

    &.loading {
      z-index: -1;
      display: flex;
      overflow: hidden;
      opacity: 1;

      .spinner-border {
        opacity: 1;
      }
    }

    .spinner-border {
      margin: auto;
      margin-top: calc(50vh - 107px);
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
  }

  .back {
    text-align: left;
    padding-top: 16px;
    margin-bottom: 32px;
  }

  .no-results-found {
    @media screen and (max-width: 577px) {
      font-size: 24px;
      padding-top: 32px;
    }

    @media screen and (min-width: 578px) {
      font-size: 32px;
      padding-top: 32px;
    }
  }

  .main-wrapper {
    padding: 8px;
    align-items: flex-start;

    .asset-data-section {
      padding: 16px;
      background-color: var(--grey-50);
      border-radius: 8px;
      .nft-media {
        min-height: 160px;
        display: flex;
        margin-bottom: 2em;
        border-radius: 8px;
        img {
          border-radius: 8px;
        }

        .single-image {
          margin: auto;
          width: 100%;
        }

        &.is-collection {
          padding-right: 0;
          padding-left: 0;
          margin: 0;
          --bs-gutter-x: 0;
        }

        .collection {
          position: relative;
          height: 100%;
          width: 100%;
          --bs-gutter-x: 0;

          img {
            padding: 1px;
          }
        }
      }

      .nft-data {
        text-align: left;
        .asset-info-headline {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #131415;
        }
        .info-item {
          &:not(:last-child) {
            margin-bottom: 1em;
          }

          .name,
          .value,
          .link-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 3px;
          }

          .value {
            color: var(--black);
            overflow-wrap: break-word;
          }
          .name {
            color: var(--black-tertiary);
          }
          .link-text {
            text-decoration: none;
            color: #0e3fe6;
          }
        }
      }
    }
  }
}

.asset-details {
  position: relative;
  @media screen and (min-width: 992px) {
    padding-left: 50px;
    padding-top: 30px;
    max-width: 992px;
  }
  @media screen and (max-width: 991px) {
    padding-left: 0px;
    padding-top: 60px;
  }
  .headline-separator {
    margin-top: 8px;
  }

  .headline {
    display: flex;
    flex-direction: column;

    .title {
      display: inline-flex;
      align-content: flex-start;
      margin-bottom: 8px;

      &.small {
        //styleName: 04 - Inline/Inline 14px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;

        .value {
          color: var(--blue);
        }
      }

      .label {
        color: var(--black-tertiary);
        margin-right: 24px;
      }

      .buy-btn {
        padding: 12px 16px;
        background: #4744df;
        border-radius: 8px;
        border: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: white;
        text-decoration: none;
        text-transform: capitalize;
        @media screen and (min-width: 992px) {
          position: absolute;
          right: 20px;
          top: 20px;
        }
        @media screen and (max-width: 991px) {
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
      .value {
        text-align: left;
        color: var(--black);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .link-text {
        text-decoration: none;
        color: #0e3fe6;
      }
      .ual-label {
        color: var(--black-tertiary);
        margin-right: 10px;
        font-size: 16px;
        @media screen and (min-width: 578px) {
          line-height: 31px;
        }
        @media screen and (max-width: 577px) {
          font-size: 16px;
        }
      }
      .ual-value {
        text-align: left;
        color: var(--black);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        @media screen and (min-width: 578px) {
          line-height: 31px;
        }
        @media screen and (max-width: 577px) {
          line-height: 25px;
        }
      }
    }
  }

  .asset-feed {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .title {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .transaction {
      display: flex;
      padding: 16px 0;
      text-align: left;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--grey-200);
      }

      .icon {
        margin-right: 16px;
      }

      .tx-info {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        word-break: break-all;
        color: var(--black);
        .timestamp {
          margin-top: 8px;
          color: var(--black-tertiary);
        }
      }
    }
  }

  .graph-section {
    margin: 40px 0;
  }

  .sdk-section {
    margin: 20px 0;
    text-align: left;
    .link {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #0e3fe5;
      text-decoration-line: underline;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 10px;
      color: #131415;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 48px;
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  @media screen and (min-width: 578px) {
    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
    }
  }
}

.asset-state-graph {
  margin-top: 40px;
  position: relative;

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #131415;
    text-align: left;
  }

  .triple-graph-container {
    .loading-msg {
      text-align: left;
    }

    width: 100%;
    height: 400px;
    min-height: 400px;
  }
}

.validation-section {
  margin-top: 48px;

  .title {
    margin-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #131415;
    text-align: left;
  }

  .assertions {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .assertion {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      padding: 12px;
      cursor: pointer;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

      &:hover,
      &.selected {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)),
          #0e3fe5;
        color: var(--accent-blue);
        border-radius: 8px;
      }
    }
  }
}
</style>
