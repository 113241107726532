<template>
  <div
    v-if="
      languagesLoaded &&
      verifiable_claims.hasOwnProperty('verifiableClaims') &&
      verifiable_claims.verifiableClaims.length > 0
    "
    class="validation-component-standalone"
  >
    <div
      v-for="(section, index) in verifiable_claims.verifiableClaims"
      :key="index"
      class="section-wrapper"
    >
      <div
        v-for="(sectionBlock, index1) in section"
        :key="index1"
        class="section-block"
        :class="[
          'section-block-' + (index + 1) + '-progress-line-' + (index1 + 1),
          generatePaddingIfLastInSection(index, index1),
        ]"
      >
        <!--Section circle-->
        <div v-if="index1 === 0" class="section-circle">
          <lottie
            :ref="'green-circle-section-' + index"
            class="normal-circle"
            style="position: absolute; top: 0; margin-top: -1px"
            :options="CircleGreen"
            @animCreated="sectionAnimation2"
          />

          <lottie
            :ref="'green-2-red-circle-section-' + index"
            class="transition-circle"
            style="position: absolute; top: 0"
            :options="CircleGreen2Red"
            @animCreated="sectionAnimation2"
          />

          <lottie
            :ref="'green-2-blue-circle-section-' + index"
            class="transition-circle-blue"
            style="position: absolute; top: 0"
            :options="CircleGreen2Blue"
            @animCreated="sectionAnimation2"
          />

          <lottie
            :ref="'loader-white-section-' + index"
            style="position: absolute; top: 0"
            :options="LoaderWhite"
            @animCreated="sectionAnimation2"
          />

          <lottie
            :ref="'check-white-section-' + index"
            style="position: absolute; top: 0"
            :options="CheckWhite"
            @animCreated="sectionAnimation2"
          />

          <lottie
            :ref="'x-white-section-' + index"
            style="position: absolute; top: 0"
            :options="XWhite"
            @animCreated="sectionAnimation2"
          />

          <lottie
            :ref="'pending-white-section-' + index"
            style="width: 75%; height: 75%; overflow: visible; position: absolute; left: 3px"
            :options="PendingWhite"
            @animCreated="sectionAnimation2"
          />
        </div>
        <!--Section circle-->

        <!--Progress pipe-->
        <div
          :class="[
            index1 === 0 && section.length > 1 ? 'top-24' : '',
            index === 0 ? 'first-section-progress' : '',
            uniqueComponentKey + 'section-' + (index + 1) + '-progress-line-' + (index1 + 1),
          ]"
          class="section-progress"
        ></div>
        <!--Progress pipe-->

        <!--Section HEADLINE-->
        <p
          v-if="sectionBlock.hasOwnProperty('isHeadline') && sectionBlock.isHeadline"
          class="validation-headline"
          :class="generateClasses(section, index1)"
        >
          {{
            generateVerificationSectionHeadline(index, index1)
              | generateTranslation(selectedLanguage, languagesDB)
              | changeNeedleForVariable(expiry_date, selectedLanguage)
          }}
        </p>
        <!--Section HEADLINE-->

        <!--SUB-Section HEADLINE-->
        <div v-else class="section-text-wrapper">
          <div class="text-below-button">
            <span class="icon-box">
              <lottie
                :ref="'loader-dark-section-' + index + '-line-' + index1"
                :options="LoaderDark"
                @animCreated="sectionAnimation"
              />

              <lottie
                :ref="'check-dark-section-' + index + '-line-' + index1"
                style="position: absolute; top: 0"
                :options="CheckDark"
                @animCreated="sectionAnimation1"
              />

              <lottie
                :ref="'x-dark-section-' + index + '-line-' + index1"
                style="position: absolute; top: 0"
                :options="XDark"
                @animCreated="sectionAnimation2"
              />

              <lottie
                :ref="'pending-dark-section-' + index + '-line-' + index1"
                style="width: 84%; height: 84%; overflow: visible; position: absolute; top: 0"
                :options="PendingDark"
                @animCreated="sectionAnimation2"
              />
            </span>
            <p class="validation-sub-headline" :class="generateClasses(section, index1)">
              {{
                generateVerificationSectionTitle(index, index1)
                  | generateTranslation(selectedLanguage, languagesDB)
              }}
            </p>
          </div>

          <div v-if="sectionBlock.hasOwnProperty('button')" class="button-wrapper">
            <el-button
              class="validation-component-standalone-button"
              :disabled="
                animationsInProgress ||
                validationResult === 'failed' ||
                validationResult === 'pending'
              "
              @click="goToLink(sectionBlock.button)"
            >
              <img
                v-if="sectionBlock.button.hasOwnProperty('icon')"
                :src="generateButtonIcon(sectionBlock.button.icon)"
                alt=""
              />
              {{ sectionBlock.button.title | generateTranslation(selectedLanguage, languagesDB) }}
            </el-button>
          </div>
        </div>
        <!--SUB-Section HEADLINE-->

        <div v-if="section.length === 1">
          <div class="validation-empty-div"></div>
        </div>
      </div>
    </div>
    <div class="section-wrapper">
      <div class="section-block">
        <!--Section circle-->
        <div class="section-circle-final">
          <lottie
            ref="final-section-verified"
            style="position: absolute"
            class="final-section-verified"
            :options="VerifyVerified"
            @animCreated="sectionAnimation"
          />
          <lottie
            ref="final-section-verified-checks"
            style="position: absolute"
            class="final-section-verified-checks"
            :options="DoubleCheckWhite"
            @animCreated="sectionAnimation"
          />

          <lottie
            ref="final-section-pending"
            style="position: absolute"
            class="final-section-pending"
            :options="VerifyPending"
            @animCreated="sectionAnimation"
          />

          <lottie
            ref="final-section-pending-icon"
            style="
              width: 55%;
              height: 55%;
              overflow: hidden;
              position: absolute;
              visibility: visible;
              top: 10px;
              left: 8px;
            "
            class="final-section-pending-icon"
            :options="PendingWhite"
            @animCreated="sectionAnimation"
          />

          <lottie
            ref="final-section-failed"
            style="position: absolute; left: -1px"
            class="final-section-failed"
            :options="VerifyFailed"
            @animCreated="sectionAnimation"
          />

          <lottie
            ref="final-section-failed-icon"
            style="width: 96%; height: 96%; overflow: hidden; position: absolute; left: -0.5px"
            class="final-section-failed-icon"
            :options="XWhite"
            @animCreated="sectionAnimation"
          />
        </div>

        <div class="verification-result">
          <p class="verification-result-headline">
            {{
              generateVerificationResultHeadline()
                | generateTranslation(selectedLanguage, languagesDB)
            }}
          </p>

          <p class="verification-additional-text">
            {{
              generateVerificationResultAdditionalTexts()
                | generateTranslation(selectedLanguage, languagesDB)
            }}
          </p>

          <!--          <div v-if="validationResult !== null && validationResult !== 'in-progress'" class="verify-again-wrapper">-->
          <!--            <el-button class="validation-component-standalone-button" :disabled="animationsInProgress" @click="verifyAgain">-->
          <!--              <img :src="generateButtonIcon('verify-again')" alt="" />-->
          <!--              {{ 'VERIFY AGAIN' | generateTranslation(selectedLanguage, languagesDB) }}-->
          <!--            </el-button>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckWhite from './lottie-animations/Check one line - White (1).json';
import CheckDark from './lottie-animations/Check one line - Dark (1).json';

import DoubleCheckWhite from './lottie-animations/Check - white.json';
import DoubleCheckDark from './lottie-animations/Check -dark.json';

import CircleBlue from './lottie-animations/Circle - Blue.json';
import CircleGreen from './lottie-animations/Circle - Green.json';
import CircleRed from './lottie-animations/Circle - Red.json';

import CircleGreen2Blue from './lottie-animations/Circle - Green to Blue.json';
import CircleGreen2Red from './lottie-animations/Circle - Green to Red.json';

import LoaderWhite from './lottie-animations/Loader - white.json';
import LoaderDark from './lottie-animations/Loader - dark.json';

import PendingWhite from './lottie-animations/Pending - white.json';
import PendingDark from './lottie-animations/Pending - dark.json';

import XWhite from './lottie-animations/failed-white.json';
import XDark from './lottie-animations/failed-dark.json';

import VerifyVerified from './lottie-animations/Verify - Green (1).json';
import VerifyPending from './lottie-animations/Verify - Blue (1).json';
import VerifyFailed from './lottie-animations/Verify - Red (1).json';

window.VALIDATION_COMPONENT = {};
window.VALIDATION_COMPONENT.$ = require('jquery');

import Lottie from 'vue-lottie';

export default {
  name: 'ValidationComponent',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    lottie: Lottie,
  },
  filters: {
    generateTranslation(word, selectedLanguage, languages) {
      if (word !== '') {
        return languages[selectedLanguage][word];
      } else {
        return '';
      }
    },
    changeNeedleForVariable(value, expiry_date, selectedLanguage) {
      function reformatDate(value) {
        if (!value) return '';
        const cut = value.substr(0, 10).replace('-', '/').replace('-', '/');
        const p = cut.split(/\D/g);

        const day = p[2];
        const month = p[1];
        const year = p[0];

        switch (selectedLanguage) {
          case 'english':
            return [year, month, day].join('/');

          case 'french':
            return [year, month, day].join('/');

          case 'japanese':
            return [day, month, year].join('/');

          case 'chinese':
            return [day, month, year].join('/');

          default:
            return [year, month, day].join('/');
        }
      }

      if (value.search('YYYY-MM-DD') > 0) {
        const formattedAccordingToLanguage = reformatDate(expiry_date);
        return value.replace('YYYY-MM-DD', formattedAccordingToLanguage);
      }

      if (value.search('AAAA-MM-JJ') > 0) {
        const formattedAccordingToLanguage = reformatDate(expiry_date);
        return value.replace('AAAA-MM-JJ', formattedAccordingToLanguage);
      }

      return value;
    },
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'verifiableClaims',
    // eslint-disable-next-line vue/require-prop-types
    'blockchainUtilities',
    // eslint-disable-next-line vue/require-prop-types
    'exportedDataset',
    // eslint-disable-next-line vue/require-prop-types
    'languages',
    // eslint-disable-next-line vue/require-prop-types
    'selectedLang',
    // eslint-disable-next-line vue/require-prop-types
    'erc',
    // eslint-disable-next-line vue/require-prop-types
    'unpublished',
    // eslint-disable-next-line vue/require-prop-types
    'expiryDate',
    // eslint-disable-next-line vue/require-prop-types
    'identifier',
    // eslint-disable-next-line vue/require-prop-types
    'parentKey',
  ],
  data() {
    return {
      uniqueComponentKey: null,
      expiry_date: '',
      componentKey: null,
      languagesLoaded: false,
      selectedLanguage: '',
      languagesDB: {},
      iconHeight: '100%',
      iconWidth: '100%',
      verifiable_claims: {},
      animationsInProgress: false,
      validationResult: null,
      activeProgressLine: null,
      activeSection: null,
      verifiedSections: {},
      pendingSections: {},
      failedSections: {},
      CheckWhite: { animationData: CheckWhite, loop: false, autoplay: false },
      CheckDark: { animationData: CheckDark, loop: false, autoplay: false },
      DoubleCheckWhite: { animationData: DoubleCheckWhite, loop: false, autoplay: false },
      DoubleCheckDark: { animationData: DoubleCheckDark, loop: false, autoplay: false },
      CircleBlue: { animationData: CircleBlue, loop: false, autoplay: false },
      CircleGreen: { animationData: CircleGreen, loop: false, autoplay: false },
      CircleRed: { animationData: CircleRed, loop: false, autoplay: false },
      CircleGreen2Blue: { animationData: CircleGreen2Blue, loop: false, autoplay: false },
      CircleGreen2Red: { animationData: CircleGreen2Red, loop: false, autoplay: false },
      LoaderWhite: { animationData: LoaderWhite, loop: true, autoplay: false },
      LoaderDark: { animationData: LoaderDark, loop: true, autoplay: false },
      PendingWhite: { animationData: PendingWhite, loop: false, autoplay: false },
      PendingDark: { animationData: PendingDark, loop: false, autoplay: false },
      XWhite: { animationData: XWhite, loop: true, autoplay: false },
      XDark: { animationData: XDark, loop: true, autoplay: false },
      VerifyVerified: { animationData: VerifyVerified, loop: false, autoplay: false },
      VerifyPending: { animationData: VerifyPending, loop: false, autoplay: false },
      VerifyFailed: { animationData: VerifyFailed, loop: false, autoplay: false },
      processedSections: {},
      pendingColorHash: '#136BC8',
      failedColorHash: '#D72D18',
      verifiedColorHash: '#00A3AD',
      lottieAnimations: [],
      dataStructure: true,
      possibleResults: [true, false, null],
      testingResult: null,
      firstFinalizedReplicationInfoObject: null,
      DCNodeWallet: null,
    };
  },
  computed: {},
  watch: {
    validationResult(val) {
      if (val !== null) {
        this.$emit('validationResult', val);
        this.changeColorOfVerification(val);

        this.playSectionStatusAnimations();

        if (val === 'verified') {
          this.playValidationResultAnimation();
        } else {
          if (val !== 'in-progress') {
            this.animateWholeValidationProcess();
            this.playValidationResultAnimation();

            // this.animateCurrentlyActiveSection();
          }
        }
      }
    },
    languagesDatabase(val) {
      // eslint-disable-next-line no-prototype-builtins
      if (val !== null && val.hasOwnProperty('english')) {
        this.languagesDB = this.languagesDatabase;
        this.languagesLoaded = true;
      }
    },
    languagesDB(val) {
      // eslint-disable-next-line no-prototype-builtins
      if (val !== null && val.hasOwnProperty('english')) {
        this.languagesLoaded = true;
      }
    },
    expiryDate(val) {
      this.expiry_date = val;
    },
    expiry_date() {},
    languages(val) {
      this.languagesDB = val;
    },
  },
  created() {},
  mounted() {
    this.makeDynamicComponentKey(5).then((key) => {
      this.uniqueComponentKey = key;
    });

    this.listenEvents();

    this.verifiable_claims = this.verifiableClaims;

    this.languagesDB = this.languages;

    this.selectedLanguage = this.selectedLang;

    this.expiry_date = this.expiryDate;

    this.verifiable_claims.verifiableClaims.forEach((section, index) => {
      const key = `section${index}Verified`;
      this[key] = null;
    });

    setTimeout(() => {
      if (this.unpublished) {
        this.validationResult = 'pending';
        this.animationsInProgress = false;
      } else {
        this.startAnimationsAuto();
      }
    }, 800);
  },
  methods: {
    async getFirstFinalizedReplicationInfoObject() {
      return (this.firstFinalizedReplicationInfoObject =
        await this.blockchainUtilities.getFirstFinalizedReplicationInfoObject(
          this.exportedDataset,
        ));
    },
    async getDCNodeWallet() {
      return (this.DCNodeWallet = await this.blockchainUtilities.getDCNodeWallet(
        this.exportedDataset,
        this.firstFinalizedReplicationInfoObject,
      ));
    },
    async makeDynamicComponentKey(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    listenEvents() {
      window.EventBus.$on('selected-language', (selectedLanguage) => {
        this.selectedLanguage = selectedLanguage;
      });
    },
    goToLink(sectionButton) {
      const link = sectionButton.link;
      if (link === 'explorer') {
        const identifier = JSON.parse(this.exportedDataset.formatted_dataset)['@graph'][0]['@id'];
        const url = `https://explorer.origintrail.io/data-sets/?query=${identifier}&type=id&externalRequest=true&network=${sectionButton.network}`;
        window.open(url, '_blank');
      } else if (link === 'blockchain') {
        const transaction_hash =
          this.firstFinalizedReplicationInfoObject.offer_creation_transaction_hash;
        if (this.firstFinalizedReplicationInfoObject.blockchain_id.includes('ethr')) {
          window.open('https://rinkeby.etherscan.io/tx/' + transaction_hash, '_blank');
        } else {
          window.open('https://blockscout.com/xdai/mainnet/tx/' + transaction_hash, '_blank');
        }
      }
    },
    subSectionIsLast(section, sectionBlockIndex) {
      if (section.length - 1 == sectionBlockIndex) {
        return true;
      } else {
        return false;
      }
    },
    isFirstAndOnlySubSection(section) {
      return section.length === 1;
    },
    isLastSection(sectionMainIndex) {
      return this.verifiable_claims.verifiableClaims.length === sectionMainIndex;
    },
    dontHaveSubSections(sectionMainIndex) {
      return this.verifiable_claims.verifiableClaims[sectionMainIndex - 1].length === 1;
    },
    isLastSubSection(sectionMainIndex, progressLineMainIndex) {
      return (
        this.verifiable_claims.verifiableClaims[sectionMainIndex - 1].length ===
        progressLineMainIndex
      );
    },
    async startAnimationsAuto() {
      this.animationsInProgress = true;
      this.validationResult = 'in-progress';

      for (let y = 0; y < this.verifiable_claims.verifiableClaims.length; y++) {
        const section = this.verifiable_claims.verifiableClaims[y];
        const sectionIndex = y;

        for (let i = 0; i < section.length; i++) {
          const subSection = section[i];
          const sectionBlockIndex = i;

          const sectionMainIndex = sectionIndex + 1;
          const progressLineMainIndex = sectionBlockIndex + 1;

          // eslint-disable-next-line no-prototype-builtins
          if (subSection.hasOwnProperty('method')) {
            let blockchainUtilitiesSDK = null;
            // eslint-disable-next-line no-prototype-builtins
            if (this.blockchainUtilities.hasOwnProperty('default')) {
              blockchainUtilitiesSDK = this.blockchainUtilities.default;
            } else {
              blockchainUtilitiesSDK = this.blockchainUtilities;
            }
            // eslint-disable-next-line no-prototype-builtins
            if (blockchainUtilitiesSDK.hasOwnProperty(subSection.method)) {
              this.activeSection = sectionMainIndex;
              this.activeProgressLine = progressLineMainIndex;
              let sectionResult = null;

              /////MAIN METHOD///
              // eslint-disable-next-line no-prototype-builtins
              if (subSection.hasOwnProperty('additionalData')) {
                sectionResult = await blockchainUtilitiesSDK[subSection.method](
                  this.exportedDataset,
                  this.firstFinalizedReplicationInfoObject,
                  this[subSection.additionalData],
                );
              } else {
                sectionResult = await blockchainUtilitiesSDK[subSection.method](
                  this.exportedDataset,
                  this.firstFinalizedReplicationInfoObject,
                );
              }
              /////MAIN METHOD///

              if (this.isFirstAndOnlySubSection(section, sectionBlockIndex)) {
                // eslint-disable-next-line no-unused-vars
                const circleAnimation = await this.lottiePlaySectionCircleAnimation(
                  sectionMainIndex,
                );
                // eslint-disable-next-line no-unused-vars
                const circleLoaderAnimation =
                  this.lottiePlaySectionLoaderAnimation(sectionMainIndex);
              } else {
                this.lottiePlayDarkLoadingAnimation(sectionMainIndex, progressLineMainIndex);
              }

              const animationResult2 = await this.animateSection(
                sectionMainIndex,
                progressLineMainIndex,
              );

              if (animationResult2) {
                this.lottieStopAnimation(sectionMainIndex, progressLineMainIndex);

                if (sectionResult) {
                  this.storeVerificationResultForSection(
                    sectionMainIndex - 1,
                    progressLineMainIndex - 1,
                    'verified',
                  );

                  this.lottiePlayCheckAnimation(sectionMainIndex, progressLineMainIndex);

                  if (this.subSectionIsLast(section, sectionBlockIndex)) {
                    this.lottieStopSectionLoaderAnimation(sectionMainIndex);
                    this.lottiePlayCheckForSectionAnimation(sectionMainIndex);
                    this.setFullHeightOfPipeForVerified(
                      sectionMainIndex,
                      progressLineMainIndex,
                      '108%',
                    );
                  } else {
                    this.setFullHeightOfPipeForVerified(
                      sectionMainIndex,
                      progressLineMainIndex,
                      '100%',
                    );
                  }

                  if (
                    this.isLastSection(sectionMainIndex) &&
                    this.dontHaveSubSections(sectionMainIndex)
                  ) {
                    this.animationsInProgress = false;
                    this.validationResult = 'verified';
                  }

                  if (
                    this.isLastSection(sectionMainIndex) &&
                    this.isLastSubSection(sectionMainIndex, progressLineMainIndex)
                  ) {
                    this.animationsInProgress = false;
                    this.validationResult = 'verified';
                  }

                  //if its pending
                } else if (sectionResult === null) {
                  this.storeVerificationResultForSection(
                    sectionMainIndex - 1,
                    progressLineMainIndex - 1,
                    'pending',
                  );
                  this.lottiePlayPendingAnimation(sectionMainIndex, progressLineMainIndex);
                  return null;
                } else {
                  this.storeVerificationResultForSection(
                    sectionMainIndex - 1,
                    progressLineMainIndex - 1,
                    'failed',
                  );
                  this.lottiePlayXAnimation(sectionMainIndex, progressLineMainIndex);
                  return false;
                }
              }
            } else {
              // console.log('ne postoji metoda ', subSection.method, this.blockchainUtilities.default);
            }
          } else {
            // eslint-disable-next-line no-unused-vars
            const circleAnimation = await this.lottiePlaySectionCircleAnimation(sectionMainIndex);
            // eslint-disable-next-line no-unused-vars
            const circleLoaderAnimation = this.lottiePlaySectionLoaderAnimation(sectionMainIndex);

            const animationResult1 = await this.animateSection(
              sectionMainIndex,
              progressLineMainIndex,
              500,
            );
            if (animationResult1) {
              this.storeVerificationResultForSection(
                sectionMainIndex - 1,
                progressLineMainIndex - 1,
                'verified',
              );
              this.lottieStopAnimation(sectionMainIndex, progressLineMainIndex);
              this.lottiePlayCheckAnimation(sectionMainIndex, progressLineMainIndex);
              this.setFullHeightOfPipeForVerified(sectionMainIndex, progressLineMainIndex, '100%');
            }

            // console.log('ne postoji method key', subSection);
          }
        }
      }
    },
    wait: (duration) =>
      new Promise((resolve) => {
        setTimeout(resolve, duration);
      }),
    resetAnimations() {
      const first = this.possibleResults[0];
      const second = this.possibleResults[1];
      const third = this.possibleResults[2];

      this.possibleResults[0] = second;
      this.possibleResults[1] = third;
      this.possibleResults[2] = first;

      this.verifiedSections = {};
      this.pendingSections = {};
      this.failedSections = {};
      this.validationResult = null;

      const finalSectionFailedClass = `final-section-failed`;
      const finalSectionFailedIcon = `final-section-failed-icon`;

      const finalSectionVerifiedClass = `final-section-verified`;
      const finalSectionVerifiedChecks = `final-section-verified-checks`;

      const finalSectionPendingClass = `final-section-pending`;
      const finalSectionPendingIcon = `final-section-pending-icon`;

      const transitionCircle = `transition-circle`;
      const transitionCircleBlue = `transition-circle-blue`;

      window.VALIDATION_COMPONENT.$(`.${finalSectionVerifiedClass}`).css({ visibility: 'hidden' });
      window.VALIDATION_COMPONENT.$(`.${finalSectionVerifiedChecks}`).css({ visibility: 'hidden' });

      window.VALIDATION_COMPONENT.$(`.${finalSectionFailedClass}`).css({ visibility: 'hidden' });
      window.VALIDATION_COMPONENT.$(`.${finalSectionFailedIcon}`).css({ visibility: 'hidden' });

      window.VALIDATION_COMPONENT.$(`.${finalSectionPendingClass}`).css({ visibility: 'hidden' });
      window.VALIDATION_COMPONENT.$(`.${finalSectionPendingIcon}`).css({ visibility: 'hidden' });

      window.VALIDATION_COMPONENT.$(`.${transitionCircle}`).css({ visibility: 'hidden' });
      window.VALIDATION_COMPONENT.$(`.${transitionCircleBlue}`).css({ visibility: 'hidden' });

      this.processedSections = {};

      this.verifiable_claims.verifiableClaims.forEach((section, index) => {
        section.forEach((sectionBlock, index1) => {
          const checkWhiteClass = `check-white-section-${index}`;
          const loaderWhite = `loader-white-section-${index}`;
          const xWhite = `x-white-section-${index}`;
          const pendingWhite = `pending-white-section-${index}`;

          const green2Red = `green-2-red-circle-section-${index}`;
          const green2Blue = `green-2-blue-circle-section-${index}`;

          const checkDarkClass = `check-dark-section-${index}-line-${index1}`;
          const loaderDark = `loader-dark-section-${index}-line-${index1}`;
          const xDark = `x-dark-section-${index}-line-${index1}`;
          const pendingDark = `pending-dark-section-${index}-line-${index1}`;

          const finalSectionFailedClass = `final-section-failed`;
          const finalSectionFailedIcon = `final-section-failed-icon`;

          const finalSectionVerifiedClass = `final-section-verified`;
          const finalSectionVerifiedChecks = `final-section-verified-checks`;

          const finalSectionPendingClass = `final-section-pending`;
          const finalSectionPendingIcon = `final-section-pending-icon`;

          const greenCircle = `green-circle-section-${index}`;

          const arrayOfClasses = [
            checkDarkClass,
            checkWhiteClass,
            finalSectionFailedClass,
            finalSectionVerifiedClass,
            finalSectionPendingClass,
            finalSectionVerifiedChecks,
            green2Red,
            green2Blue,
            greenCircle,
            loaderDark,
            loaderWhite,
            xDark,
            xWhite,
            pendingDark,
            pendingWhite,
            finalSectionFailedIcon,
            finalSectionPendingIcon,
          ];

          arrayOfClasses.forEach((ref) => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.$refs.hasOwnProperty(ref)) {
              if (this.$refs[ref].length > 0) {
                const mainObject = this.$refs[ref][0];
                // eslint-disable-next-line no-prototype-builtins
                if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
                  mainObject.anim.goToAndStop(0);
                }
              } else {
                const mainObject = this.$refs[ref];
                // eslint-disable-next-line no-prototype-builtins
                if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
                  mainObject.anim.goToAndStop(0);
                }
              }
            }
          });

          const className =
            this.uniqueComponentKey + 'section-' + (index + 1) + '-progress-line-' + (index1 + 1);
          const child = window.VALIDATION_COMPONENT.$(`.${className}`);
          child.css('height', 0);
        });
      });

      const normalCircle = window.VALIDATION_COMPONENT.$('.normal-circle');
      normalCircle.css({ visibility: 'visible' });

      const progressLine = window.VALIDATION_COMPONENT.$('.section-progress');
      progressLine.css({ backgroundColor: this.verifiedColorHash });
    },
    lottiePlayDarkLoadingAnimation(section, progressLine) {
      const ref = 'loader-dark-section-' + (section - 1) + '-line-' + (progressLine - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    lottieStopAnimation(section, progressLine) {
      const ref = 'loader-dark-section-' + (section - 1) + '-line-' + (progressLine - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.stop();
        }
      }
    },
    lottiePlayCheckAnimation(section, progressLine) {
      const ref = 'check-dark-section-' + (section - 1) + '-line-' + (progressLine - 1);
      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    lottiePlayPendingAnimation(section, progressLine) {
      const ref = 'pending-dark-section-' + (section - 1) + '-line-' + (progressLine - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    lottiePlayXAnimation(section, progressLine) {
      const ref = 'x-dark-section-' + (section - 1) + '-line-' + (progressLine - 1);
      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    lottiePlaySectionCircleAnimation(section) {
      const ref = 'green-circle-section-' + (section - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];

        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 1000);
        });
      }
    },
    lottiePlaySectionLoaderAnimation(section) {
      const ref = 'loader-white-section-' + (section - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];

        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.play();
        }
      }
    },
    lottieStopSectionLoaderAnimation(section) {
      const ref = 'loader-white-section-' + (section - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.stop();
        }
      }
    },
    playTransitionToRedAnimation() {
      this.verifiable_claims.verifiableClaims.forEach((row, index) => {
        const ref = 'green-2-red-circle-section-' + index;
        // eslint-disable-next-line no-prototype-builtins
        if (this.$refs.hasOwnProperty(ref)) {
          const mainObject = this.$refs[ref][0];
          // eslint-disable-next-line no-prototype-builtins
          if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
            mainObject.anim.goToAndPlay(1500);
          }
        }
      });
    },
    playTransitionToBlueAnimation() {
      this.verifiable_claims.verifiableClaims.forEach((row, index) => {
        const ref = 'green-2-blue-circle-section-' + index;
        // eslint-disable-next-line no-prototype-builtins
        if (this.$refs.hasOwnProperty(ref)) {
          const mainObject = this.$refs[ref][0];
          // eslint-disable-next-line no-prototype-builtins
          if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
            mainObject.anim.goToAndPlay(1500);
          }
        }
      });
    },
    lottiePlayCheckForSectionAnimation(section) {
      const ref = 'check-white-section-' + (section - 1);
      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];
        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    lottiePlayXForSectionAnimation(section) {
      const ref = 'x-white-section-' + (section - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];

        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    lottiePlayPendingForSectionAnimation(section) {
      const ref = 'pending-white-section-' + (section - 1);

      // eslint-disable-next-line no-prototype-builtins
      if (this.$refs.hasOwnProperty(ref)) {
        const mainObject = this.$refs[ref][0];

        // eslint-disable-next-line no-prototype-builtins
        if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
          mainObject.anim.goToAndPlay(0);
        }
      }
    },
    animateSection(section, progressLine, duration = null) {
      return new Promise((resolve) => {
        const className =
          this.uniqueComponentKey + 'section-' + section + '-progress-line-' + progressLine;

        const parentClass = 'section-block-' + section + '-progress-line-' + progressLine;

        const parent = window.VALIDATION_COMPONENT.$(`.${parentClass}`);

        const child = window.VALIDATION_COMPONENT.$(`.${className}`);

        const parentHeight = parent.height();

        const indexOfLastInSection =
          this.verifiable_claims.verifiableClaims[section - 1].length - 1;

        let progressLineHeight = 0;

        if (indexOfLastInSection + 1 === progressLine) {
          progressLineHeight = parentHeight + 13;
        } else {
          progressLineHeight = progressLine === 1 ? parentHeight - 23 : parentHeight;
        }

        child.animate(
          { height: progressLineHeight },
          {
            easing: 'swing',
            duration: duration !== null ? duration : 3500,
            complete: function () {
              resolve(true);
            },
          },
        );
      });
    },
    setFullHeightOfPipeForVerified(section, progressLine, height) {
      const className =
        this.uniqueComponentKey + 'section-' + section + '-progress-line-' + progressLine;
      const child = window.VALIDATION_COMPONENT.$(`.${className}`);
      child.animate(
        { height: height },
        {
          easing: 'swing',
          duration: 1000,
          complete: function () {},
        },
      );
    },
    animateWholeValidationProcess() {
      const className = 'section-progress';
      const child = window.VALIDATION_COMPONENT.$(`.${className}`);
      child.animate(
        { height: '114%' },
        {
          easing: 'swing',
          duration: 200,
        },
      );
    },
    animateCurrentlyActiveSection() {
      this.animateSection(this.activeSection, this.activeProgressLine, 300);
    },
    isLastElementInBlock(section, index1) {
      if (index1 + 1 === section.length) {
        return true;
      }
    },
    isFirstSubElement(index1) {
      return index1 === 1;
    },
    // eslint-disable-next-line no-unused-vars
    generateClasses(section, index1) {
      const classes = '';
      return classes;
    },
    generateVerificationResultHeadline() {
      if (this.validationResult === 'verified') {
        return this.verifiable_claims.verificationResultHeadlines.verified;
      } else if (this.validationResult === 'pending') {
        return this.verifiable_claims.verificationResultHeadlines.pending;
      } else if (this.validationResult === 'failed') {
        return this.verifiable_claims.verificationResultHeadlines.failed;
      } else if (this.validationResult === 'in-progress') {
        return this.verifiable_claims.verificationResultHeadlines.inProgress;
      } else {
        return this.verifiable_claims.verificationResultHeadlines.inProgress;
      }
    },
    generateVerificationResultAdditionalTexts() {
      if (this.validationResult === 'verified') {
        return this.verifiable_claims.verificationResultTexts.verified;
      } else if (this.validationResult === 'pending') {
        return this.verifiable_claims.verificationResultTexts.pending;
      } else {
        return '';
      }
    },
    generateVerificationSectionTitle(index, index1) {
      //ako je verified section
      // eslint-disable-next-line no-prototype-builtins
      if (this.verifiedSections.hasOwnProperty(index)) {
        //main section verified
        if (this.verifiedSections[index].includes(index1)) {
          return this.verifiable_claims.verifiableClaims[index][index1]['verified'];
        } else {
          //check for sub-sections
          // eslint-disable-next-line no-prototype-builtins
          if (this.failedSections.hasOwnProperty(index)) {
            return this.verifiable_claims.verifiableClaims[index][index1]['failed'];
          }

          // eslint-disable-next-line no-prototype-builtins
          if (this.pendingSections.hasOwnProperty(index)) {
            return this.verifiable_claims.verifiableClaims[index][index1]['pending'];
          }

          return this.verifiable_claims.verifiableClaims[index][index1]['inProgress'];
        }

        //ako section nije verified
      } else {
        if (this.validationResult !== null) {
          if (this.validationResult === 'failed') {
            return this.verifiable_claims.verifiableClaims[index][index1]['failed'];
          }

          if (this.validationResult === 'pending') {
            return this.verifiable_claims.verifiableClaims[index][index1]['pending'];
          }
        }
        return this.verifiable_claims.verifiableClaims[index][index1]['inProgress'];
      }
    },
    generateVerificationSectionHeadline(index, index1) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        this.verifiedSections.hasOwnProperty(index) &&
        this.verifiedSections[index].length ===
          this.verifiable_claims.verifiableClaims[index].length
      ) {
        return this.verifiable_claims.verifiableClaims[index][index1]['verified'];
      } else {
        if (this.validationResult !== null) {
          if (this.validationResult === 'failed') {
            return this.verifiable_claims.verifiableClaims[index][index1]['failed'];
          }

          if (this.validationResult === 'pending') {
            return this.verifiable_claims.verifiableClaims[index][index1]['pending'];
          }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.failedSections.hasOwnProperty(index)) {
          return this.verifiable_claims.verifiableClaims[index][index1]['failed'];
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.pendingSections.hasOwnProperty(index)) {
          return this.verifiable_claims.verifiableClaims[index][index1]['pending'];
        }

        return this.verifiable_claims.verifiableClaims[index][index1]['inProgress'];
      }
    },
    storeVerificationResultForSection(section, progressLine, status) {
      if (status === 'verified') {
        // eslint-disable-next-line no-prototype-builtins
        if (this.verifiedSections.hasOwnProperty(section)) {
          this.verifiedSections[section].push(progressLine);
        } else {
          this.verifiedSections[section] = [];
          this.verifiedSections[section].push(progressLine);
        }
      }

      if (status === 'failed') {
        // eslint-disable-next-line no-prototype-builtins
        if (this.failedSections.hasOwnProperty(section)) {
          this.failedSections[section].push(progressLine);
          this.validationResult = 'failed';
        } else {
          this.failedSections[section] = [];
          this.failedSections[section].push(progressLine);
          this.validationResult = 'failed';
        }
        this.changeColorOfVerification('failed');
      }

      if (status === 'pending') {
        // eslint-disable-next-line no-prototype-builtins
        if (this.pendingSections.hasOwnProperty(section)) {
          this.pendingSections[section].push(progressLine);
          this.validationResult = 'pending';
        } else {
          this.pendingSections[section] = [];
          this.pendingSections[section].push(progressLine);
          this.validationResult = 'pending';
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (this.processedSections.hasOwnProperty(section)) {
        this.processedSections[section].push(progressLine);
      } else {
        this.processedSections[section] = [];
        this.processedSections[section].push(progressLine);
      }

      this.$forceUpdate();
    },
    changeColorOfVerification() {
      const progressLine = window.VALIDATION_COMPONENT.$('.section-progress');
      const transition2Red = window.VALIDATION_COMPONENT.$('.transition-circle');
      const transition2Blue = window.VALIDATION_COMPONENT.$('.transition-circle-blue');
      const normalCircle = window.VALIDATION_COMPONENT.$('.normal-circle');

      if (this.validationResult === 'failed') {
        progressLine.css({ backgroundColor: this.failedColorHash });
        this.animationsInProgress = false;

        transition2Red.css({ visibility: 'visible' });
        normalCircle.css({ visibility: 'hidden' });
        this.playTransitionToRedAnimation();
      } else if (this.validationResult === 'pending') {
        progressLine.css({ backgroundColor: this.pendingColorHash });
        this.animationsInProgress = false;

        transition2Blue.css({ visibility: 'visible' });
        normalCircle.css({ visibility: 'hidden' });
        this.playTransitionToBlueAnimation();
      } else {
        progressLine.css({ backgroundColor: this.verifiedColorHash });
        normalCircle.css({ visibility: 'visible' });
      }
    },
    sectionAnimation(anim) {
      this.lottieAnimations.push(anim);
    },
    sectionAnimation1() {},
    sectionAnimation2() {},
    generatePaddingIfLastInSection(index, index1) {
      const indexOfLastInSection = this.verifiable_claims.verifiableClaims[index].length - 1;

      if (indexOfLastInSection === index1) {
        return 'padding-bottom-8';
      }
    },
    async playSectionStatusAnimations() {
      if (this.validationResult === 'pending' || this.validationResult === 'failed') {
        for (let y = 0; y < this.verifiable_claims.verifiableClaims.length; y++) {
          const section = this.verifiable_claims.verifiableClaims[y];
          const sectionIndex = y;

          this.lottieStopSectionLoaderAnimation(sectionIndex + 1);

          //ako je procesovan section
          // eslint-disable-next-line no-prototype-builtins
          if (this.processedSections.hasOwnProperty(sectionIndex)) {
            if (this.processedSections[sectionIndex].length !== section.length) {
              if (this.validationResult === 'failed') {
                this.lottiePlayXForSectionAnimation(sectionIndex + 1);

                this.animateSubSectionWhichIsNotProcessed(section, sectionIndex, 'sub-section');
              } else if (this.validationResult === 'pending') {
                this.lottiePlayPendingForSectionAnimation(sectionIndex + 1);

                this.animateSubSectionWhichIsNotProcessed(section, sectionIndex, 'sub-section');
              }
            } else {
              if (
                // eslint-disable-next-line no-prototype-builtins
                this.verifiedSections.hasOwnProperty(sectionIndex) &&
                this.verifiedSections[sectionIndex].length === section.length
                // eslint-disable-next-line no-empty
              ) {
              } else {
                if (this.validationResult === 'failed') {
                  this.lottiePlayXForSectionAnimation(sectionIndex + 1);
                } else if (this.validationResult === 'pending') {
                  this.lottiePlayPendingForSectionAnimation(sectionIndex + 1);
                }
              }
            }

            //ako nije procesovan section
          } else {
            // console.log(section, 'un processed section');

            if (this.validationResult === 'failed') {
              this.lottiePlayXForSectionAnimation(sectionIndex + 1);
            } else if (this.validationResult === 'pending') {
              this.lottiePlayPendingForSectionAnimation(sectionIndex + 1);
            }

            this.animateSubSectionWhichIsNotProcessed(section, sectionIndex, 'full-section');
          }
        }
      }
    },
    animateSubSectionWhichIsNotProcessed(section, sectionIndex, type) {
      section.forEach((subSection, subSectionIndex) => {
        const progressLineMainIndex = subSectionIndex + 1;
        const sectionMainIndex = sectionIndex + 1;

        if (type === 'sub-section') {
          //if sub section icon should be rendered
          if (
            // eslint-disable-next-line no-prototype-builtins
            subSection.hasOwnProperty('method') &&
            // eslint-disable-next-line no-prototype-builtins
            this.processedSections.hasOwnProperty(sectionIndex) &&
            !this.processedSections[sectionIndex][subSectionIndex]
          ) {
            if (this.validationResult === 'pending') {
              this.lottiePlayPendingAnimation(sectionMainIndex, progressLineMainIndex);
            }

            if (this.validationResult === 'failed') {
              this.lottiePlayXAnimation(sectionMainIndex, progressLineMainIndex);
            }
          }
        } else if (type === 'full-section') {
          //if sub section icon should be rendered
          // eslint-disable-next-line no-prototype-builtins
          if (subSection.hasOwnProperty('method')) {
            if (this.validationResult === 'pending') {
              this.lottiePlayPendingAnimation(sectionMainIndex, progressLineMainIndex);
            }

            if (this.validationResult === 'failed') {
              this.lottiePlayXAnimation(sectionMainIndex, progressLineMainIndex);
            }
          }
        }
      });
    },
    async playValidationResultAnimation() {
      if (this.validationResult === 'pending') {
        const ref = 'final-section-pending';
        const refIcon = 'final-section-pending-icon';
        window.VALIDATION_COMPONENT.$(`.${ref}`).css({ visibility: 'visible' });
        window.VALIDATION_COMPONENT.$(`.${refIcon}`).css({ visibility: 'visible' });

        // eslint-disable-next-line no-prototype-builtins
        if (this.$refs.hasOwnProperty(ref)) {
          const mainObject = this.$refs[ref];
          // eslint-disable-next-line no-prototype-builtins
          if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
            mainObject.anim.goToAndPlay(0);
          }
        }

        setTimeout(() => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.$refs.hasOwnProperty(refIcon)) {
            const mainObject = this.$refs[refIcon];
            // eslint-disable-next-line no-prototype-builtins
            if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
              mainObject.anim.goToAndPlay(0);
            }
          }
        }, 1000);
      } else if (this.validationResult === 'failed') {
        const ref = 'final-section-failed';
        const refIcon = 'final-section-failed-icon';
        window.VALIDATION_COMPONENT.$(`.${ref}`).css({ visibility: 'visible' });
        window.VALIDATION_COMPONENT.$(`.${refIcon}`).css({ visibility: 'visible' });

        // eslint-disable-next-line no-prototype-builtins
        if (this.$refs.hasOwnProperty(ref)) {
          const mainObject = this.$refs[ref];
          // eslint-disable-next-line no-prototype-builtins
          if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
            mainObject.anim.goToAndPlay(0);
          }
        }

        setTimeout(() => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.$refs.hasOwnProperty(refIcon)) {
            const mainObject = this.$refs[refIcon];
            // eslint-disable-next-line no-prototype-builtins
            if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
              mainObject.anim.goToAndPlay(0);
            }
          }
        }, 1000);
      } else if (this.validationResult === 'verified') {
        const ref = 'final-section-verified';
        const refChecks = 'final-section-verified-checks';
        window.VALIDATION_COMPONENT.$(`.${ref}`).css({ visibility: 'visible' });
        window.VALIDATION_COMPONENT.$(`.${refChecks}`).css({ visibility: 'visible' });

        // eslint-disable-next-line no-prototype-builtins
        if (this.$refs.hasOwnProperty(ref)) {
          const mainObject = this.$refs[ref];
          // eslint-disable-next-line no-prototype-builtins
          if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
            mainObject.anim.goToAndPlay(0);
          }
        }

        setTimeout(() => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.$refs.hasOwnProperty(refChecks)) {
            const mainObject = this.$refs[refChecks];
            // eslint-disable-next-line no-prototype-builtins
            if (mainObject !== undefined && mainObject.hasOwnProperty('anim')) {
              mainObject.anim.goToAndPlay(0);
            }
          }
        }, 1000);
      }
    },
    generateButtonIcon(icon) {
      if (icon === 'blockchain') {
        return `${process.env.MIX_APP_URL}/images/bsi-training-certificates-images/blockchain.svg`;
      } else if (icon === 'origintrail') {
        return `${process.env.MIX_APP_URL}/images/bsi-training-certificates-images/origintrail.svg`;
      } else if (icon === 'verify-again') {
        return `${process.env.MIX_APP_URL}/images/bsi-training-certificates-images/verify-again.svg`;
      }
    },
    verifyAgain() {
      const event = new Event(`verify-again`);
      window.dispatchEvent(event);
    },
    reformatDate(value) {
      const cut = value.substr(0, 10).replace('-', '/').replace('-', '/');
      const p = cut.split(/\D/g);

      const day = p[2];
      const month = p[1];
      const year = p[0];

      switch (this.selectedLanguage) {
        case 'english':
          return [day, month, year].join('/');

        case 'french':
          return [day, month, year].join('/');

        case 'japanese':
          return [year, month, day].join('/');

        case 'chinese':
          return [year, month, day].join('/');

        default:
          return [day, month, year].join('/');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.normal-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  transform: none !important;
}

.validation-component-standalone {
  max-width: 500px;

  .validation-page.hide-validation {
    display: none;
  }

  .second-page {
    padding: 0 16px 70px 16px;
    display: none;
  }

  .second-page.show-second-page {
    display: block;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .validation-component-standalone-button {
    padding: 5px 7px;
    border: 1px solid #131415;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #131415;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    &:hover {
      border: 1px solid #131415;
      background-color: #dfdfdf;
      color: black;
    }

    &:focus {
      border: 1px solid #131415;
      background-color: #dfdfdf;
      color: black;
    }

    &:active {
      border: 1px solid #131415;
      background-color: #dfdfdf;
      color: black;
    }
  }

  .section-wrapper {
    position: relative;

    .padding-bottom-8 {
      padding-bottom: 8px;
    }

    .section-circle {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 1px;
      left: 13px;
      width: 24px;
      height: 24px;
      z-index: 1;

      .transition-circle {
        visibility: hidden;
      }

      .transition-circle-blue {
        visibility: hidden;
      }
    }

    .section-circle-final {
      position: absolute;
      top: -5px;
      width: 40px;
      height: 48px;
      left: 6px;

      .final-section-verified {
        visibility: hidden;
      }

      .final-section-verified-checks {
        width: 65% !important;
        height: 65% !important;
        overflow: hidden;
        margin: 0px auto;
        position: absolute;
        visibility: visible;
        top: 7px !important;
        left: 6px !important;
      }

      .final-section-pending {
        visibility: hidden;
      }

      .final-section-failed {
        visibility: hidden;
      }

      .final-section-badge {
        width: 120%;
        height: 120%;
        position: absolute;
        top: -7px;
        left: -5px;
      }

      .transition-circle {
        visibility: hidden;
      }

      .transition-circle-blue {
        visibility: hidden;
      }
    }

    .section-block-head {
      width: 100%;
    }

    .section-block-sub-head {
      width: 70%;
    }

    .section-block {
      padding-left: 50px;
      position: relative;
      max-width: 100%;
      //width: 80%;
      //margin-top: -4px;

      .validation-empty-div {
        min-height: 50px;
      }

      .section-text-wrapper {
        //display: flex;
        //justify-content: start;
        //align-items: center;
        padding: 0 0 8px;

        .text-below-button {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-start;
          padding-left: 23px;
          margin: 14px 0;
        }
      }

      .icon-box {
        position: relative;
        width: 24px;
        height: 24px;
        display: inline-block;
        min-width: 24px;
      }

      .validation-headline {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: #131415;
        margin-bottom: 0;
        padding-bottom: 8px;
        text-align: left;
        margin-top: 0;
      }

      .validation-sub-headline {
        width: 77%;
        margin-top: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        color: #131415;
        margin-bottom: 0;
        //padding-bottom: 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        /* display: flex; */
        /* -webkit-box-pack: start; */
        -ms-flex-pack: start;
        /* justify-content: start; */
        /* -webkit-box-align: center; */
        -ms-flex-align: center;
        /* align-items: center; */
        display: inline-block;
        text-align: left;

        .icon-box {
          position: relative;
          width: 24px;
          height: 24px;
          display: inline-block;
        }
      }

      .validation-link {
        color: #3367cd;
        cursor: pointer;
        text-decoration: underline;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        width: fit-content;
        padding: 0;
        margin: 0;
      }

      .success-rate {
        color: #606266;
        font-size: 14px;
        line-height: 150%;
        padding-left: 24px;
        min-height: 21px;
      }

      .validation-second-page-link {
        color: #3367cd;
        cursor: pointer;
        text-decoration: underline;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        width: fit-content;
        padding: 0;
        margin: 0 0 0 24px;
      }

      .validation-second-page-link.disabled-class {
        color: #808080;
      }

      .last-element-padding {
        padding-bottom: 15px;
      }

      .first-element-padding {
        padding-top: 8px;
      }

      .first-section-progress {
        //top: 23px !important;
      }

      .section-progress {
        position: absolute;
        left: 23px;
        top: 0;
        width: 5px;
        background-color: #00a3ad;
        transition: background-color 1s ease-in-out;
        -moz-transition: background-color 1s ease-in-out;
        -webkit-transition: background-color 1s ease-in-out;
        -o-transition: background-color 1s ease-in-out;
      }

      .top-24 {
        top: 23px;
      }
    }
  }

  .verification-result {
    .verification-result-headline {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #131415;
      text-align: left;
      margin-top: 0;
    }

    .verification-additional-text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #131415;
      text-align: left;
    }

    .verify-again-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
