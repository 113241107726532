import Web3 from 'web3';
import store from '../store/index';

class metamaskService {
  web3;
  networkStatus = false;
  eventsSet = false;

  async connectToMetamask() {
    try {
      if (window.ethereum) {
        if (window.ethereum.providers) {
          const metamaskProvider = window.ethereum.providers.find(
            (provider) => provider.isMetaMask,
          );
          this.web3 = new Web3(metamaskProvider);
          window.ethereum.setSelectedProvider(metamaskProvider);
        } else {
          this.web3 = new Web3(window.ethereum);
        }
        window.Web3 = this.web3;
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        await this.checkNetwork().catch(() => {
          return this.switchMetamaskChain().catch((err) => {
            store.dispatch('logout');
            throw err;
          });
        });
        this.setUpEvents();
        this.setNetworkStatus(true);
        localStorage.setItem('refuse_wallet', 'false');
        return accounts;
      } else {
        console.warn(
          'Metamask is not found on your browser, ' +
            'To use any wallet features you need to install the official metamask extension',
        );
      }
    } catch (err) {
      console.error(err);
      this.setNetworkStatus(false);
      throw err;
    }
  }

  isMetaMaskInstalled = () => {
    const { ethereum } = window;
    if (!ethereum) return false;
    this.ethereum = ethereum;
    return Boolean(ethereum && ethereum.isMetaMask);
  };

  isSavedConnection() {
    const refuse_wallet = localStorage.getItem('refuse_wallet');
    return refuse_wallet === 'false';
  }

  checkNetwork = async (chainId) => {
    if (!chainId) chainId = await window.ethereum.request({ method: 'eth_chainId' });
    if (store.getters.selectedNetwork?.id !== chainId) {
      this.setNetworkStatus(false);
      throw new Error('netError');
    }
    return true;
  };

  setNetworkStatus(boolValue) {
    this.networkStatus = boolValue;
  }

  switchMetamaskChain(chainId) {
    return window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId ?? store.getters.selectedNetwork?.id }],
    });
  }

  setUpEvents() {
    if (window.ethereum && !this.eventsSet) {
      window.ethereum.on('connect', () => {
        this.setNetworkStatus(true);
      });

      window.ethereum.on('disconnect', () => {
        this.setNetworkStatus(false);
        localStorage.setItem('refuse_wallet', 'false');
      });
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log('Account Changed');
        if (accounts?.length === 0) {
          this.disconnectFromMetamask();
        }
      });
      window.ethereum.on('chainChanged', (chainId) => {
        this.checkNetwork(chainId).catch(() => {
          this.switchMetamaskChain().catch(() => {
            store.dispatch('logout');
          });
        });
      });

      this.eventsSet = true;
    }
  }

  async disconnectFromMetamask() {
    localStorage.setItem('refuse_wallet', 'true');
    this.setNetworkStatus(false);
    this.eventsSet = false;
  }
}

export default new metamaskService();
