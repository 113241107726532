async function verifyAssertionIntegrity(dataset) {
  return new Promise((resolve) => {
    resolve(true);
    let datasets = Object.entries(dataset.datasets);
    let processedDatasets = [];
    let processedDataset = 0;
    datasets.forEach((row, index) => {
      processedDatasets[index] = [];
      let replication_info = row[1];
      if (replication_info === null) {
        resolve(null);
      }
      let processedReplicationInfos = 0;
      replication_info.forEach((replication_info_object) => {
        processedDatasets[index].push(replication_info_object.status);
        processedReplicationInfos++;
        if (processedReplicationInfos === replication_info.length) {
          processedDataset++;
          if (processedDataset === datasets.length) {
            let finalProcessed = 0;
            let finalProcessResults = [];
            processedDatasets.forEach((datasetStatuses, index) => {
              if (datasetStatuses.includes('COMPLETED') || datasetStatuses.includes('FINALIZED')) {
                finalProcessResults[index] = 'COMPLETED';
              } else if (datasetStatuses.every((v) => v === 'FAILED') || datasetStatuses.every((v) => v === 'NOT_CHOSEN')) {
                finalProcessResults[index] = 'FAILED';
              } else {
                finalProcessResults[index] = 'PENDING';
              }
              finalProcessed++;
              if (finalProcessed === processedDatasets.length) {
                if (finalProcessResults.every((v) => v === 'COMPLETED')) {
                  resolve(true);
                } else if (finalProcessResults.includes('PENDING')) {
                  resolve(null);
                } else {
                  resolve(false);
                }
              }
            });
          }
        }
      });
    });
  });
}

function verifyIssuer(dataset) {
  return new Promise((resolve) => {
    resolve(true);
    let processed = 0;
    dataset.dataCreator.forEach((row) => {
      if ((row.identifierValue = dataset.nodeIdentity)) {
        resolve(true);
      }
      processed++;
      if (processed === dataset.dataCreator.length) {
        resolve(false);
      }
    });
  });
}

function verifyDataModel() {
  return true;
}

export default { verifyAssertionIntegrity, verifyIssuer, verifyDataModel };
