export default {
  state: {
    searchKeyword: null,
    searchAction: false,
    searching: false,
  },
  mutations: {
    CALL_SEARCH(state, newState) {
      state.searchKeyword = newState;
      state.searchAction = true;
      setTimeout(() => {
        state.searchAction = false;
      }, 100);
    },
    TOGGLE_SEARCHING(state) {
      state.searching = !state.searching;
    },
  },
  actions: {
    callSearch(context, data = null) {
      if (!data) return;
      context.commit('CALL_SEARCH', data);
    },
    toggleSearching(context) {
      context.commit('TOGGLE_SEARCHING');
    },
  },
  getters: {
    getSearchAction(state) {
      return state.searchAction;
    },
    getSearchKeyword(state) {
      return state.searchKeyword;
    },
    isSearching(state) {
      return state.searching;
    },
  },
};
