<template>
  <div id="app" class="web3-explorer">
    <div class="container">
      <Header />
      <div class="general-content-container">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import metamask from './services/metamask';
export default {
  components: { Footer, Header },
  async created() {
    const selectedOTNetwork = localStorage.getItem('selected_network');
    if (selectedOTNetwork) {
      await this.$store.dispatch('changeNetwork', selectedOTNetwork);
    }
    if (metamask.isSavedConnection()) {
      await this.$store.dispatch('connectWithMetamask');
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.web3-explorer {
  height: 100vh;
}
</style>
