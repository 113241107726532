<template>
  <div class="home">
    <div class="discover-header">
      <img src="@/assets/icons/web-explorer.svg" class="explorer-name" />
      <img src="@/assets/icons/powered-by.svg" class="powered-by" />
    </div>
    <div class="network-and-search">
      <div class="network">
        Network <a href="https://origintrail.io" target="_blank">v6 {{ networkName }}</a>
      </div>
      <div class="search-box">
        <search-bar :is-searching="isSearching" @onSearch="goToSearchPage" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '../components/SearchBar';
export default {
  name: 'Home',
  components: { SearchBar },
  data() {
    return {
      isSearching: false,
    };
  },
  computed: {
    searching() {
      return this.$store.getters.isSearching;
    },
    network() {
      return this.$store.getters.selectedNetwork;
    },
    networkName() {
      return this.network?.title ?? '';
    },
  },
  methods: {
    goToSearchPage(key) {
      if (this.searching) {
        return;
      }
      if (this.$route.name !== 'Search') {
        this.$router.push(`/search-result?key=${key}`);
      }
      this.$store.dispatch('callSearch', key);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  padding-right: 1em;
  padding-left: 1em;
  height: calc(var(--minimal-main-section-height));
  .discover-header {
    margin: 2em auto;
    font-size: 36px;
    color: var(--black);
    line-height: 56px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    img {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
    .powered-by {
      max-width: 200px;
    }
    .explorer-name {
      max-width: 400px;
    }
  }
  .network-and-search {
    .search-box {
      margin-top: 1em;
      margin-right: auto;
      margin-left: auto;
      max-width: 640px;
    }
  }
}
</style>
